import './roadmap.css';

import React from 'react';

import { Nav, TimeLineFull } from '../../components';

const Roadmap = () => {
  return (
    <div
      style={{
        backgroundColor: '#610dde',
      }}
    >
      <div style={{ height: '150px', backgroundColor: '#610dde' }}>
        <Nav path="whitepaper" isDoc />
      </div>

      <section className="container-docs">
        <h1 className="m-0 p-0 burbank text-white text-center roadmap-hero-title">Roadmap</h1>
        <br />
        <br />
        <div className="container__sources">
          <div className="sources--cms">
            <h3 className="rmap-h3">PFP Mint Schedule (Phase 1)</h3>
            <ul>
              <li>
                Free Mint 1 & 2: June 18th (all day) <br />-{' '}
                <a
                  title="Add to Calendar"
                  className="text-pink"
                  data-id="qd13860052"
                  href="https://www.addevent.com/event/qd13860052"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Add to Calendar
                </a>
                <script
                  type="text/javascript"
                  src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                  async
                  defer
                />
              </li>
              <li>
                Whitelist Pre-sale: June 25th (all day) <br />-{' '}
                <a
                  title="Add to Calendar"
                  className="text-pink"
                  data-id="Ui13860100"
                  href="https://www.addevent.com/event/Ui13860100"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Add to Calendar
                </a>
                <script
                  type="text/javascript"
                  src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                  async
                  defer
                />
              </li>
              <li>
                Public Sale: July 2nd (all day) <br />-{' '}
                <a
                  title="Add to Calendar"
                  className="text-pink"
                  data-id="yr13860109"
                  href="https://www.addevent.com/event/yr13860109"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Add to Calendar
                </a>
                <script
                  type="text/javascript"
                  src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                  async
                  defer
                />
              </li>
            </ul>
          </div>

          <div className="sources--markdown">
            <h3 className="rmap-h3">Airdrops & Grants (Phase 2)</h3>
            <ul>
              <li>Book One, PDF</li>
              <li>Book One, Print</li>
              <li>Throne Grants</li>
              <li>Crystal Grants</li>
              <li>$CLASSX Token</li>
              <li>AxiomX P2E Game Pass</li>
            </ul>
          </div>

          <div className="sources--data">
            <h3 className="rmap-h3">$CLASSX Era (Phase 3)</h3>
            <ul>
              <li>Web3 Proposals & Voting</li>
              <li>Legends Mint</li>
              <li>$CLASSX University</li>
              <li>Trunks (Metadata Updates)</li>
              <li>Soulbound Tokens (SBTs)</li>
            </ul>
          </div>
        </div>

        <svg viewbox="0 0 10 100">
          <line x1="5" x2="5" y1="0" y2="100" />
        </svg>

        <div className="container__build">
          <div className="build--powered">
            <h3 className="roadmap-h3">Gneeks PFP FREE MINT</h3>
            <img
              className="pfp-gif"
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeksdotorg_small.gif"
              alt=""
            />
          </div>

          <div className="build--stack">
            <p className="body-roadmap">
              The Gneeks are the ClassX genesis NFT collection. Owning one opens the gates of
              opportunity. We&apos;re giving away multiple waves for free.
            </p>
          </div>
        </div>

        <svg viewbox="0 0 10 100">
          <line x1="5" x2="5" y1="0" y2="100" />
        </svg>

        <div className="container__deploy">
          <h3 className="roadmap-h3">Graphic Novel Book Games</h3>
          <p className="body-roadmap">
            Book One is a computer science inspired game. Use the book to decide which airdrop
            elements to mutate with.
            <br />
          </p>
          <img
            className="book-one-game"
            alt=""
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-variant-1.png"
          />
          <br />
          <p className="body-roadmap">
            Hold your PFP and get a free airdrop of Book One, Into the Ether. Get both the PDF and
            Print edition delivered to your home. Use your book game to unlock AxiomX elements,
            symbols and legends, and mutate/upgrade your PFP to a god.
          </p>
        </div>

        <svg viewbox="0 0 10 100">
          <line x1="5" x2="5" y1="0" y2="100" />
        </svg>

        <div className="container__deploy2">
          <h3 className="roadmap-h3">$ClassX University Launch</h3>
          <p className="body-roadmap">
            Help develop the first on-chain curriculum, <br />
            contribute to the culture and own part of a global initiative.
            <br />
            <br />
          </p>
          <img
            className="school"
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/classx-university-metaverse.png"
            alt=""
          />
          <br />
          <p className="body-roadmap">
            The Gneeks.org{' '}
            <a
              href="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/big-discord-button.png"
              className="text-pink"
            >
              Discord Server
            </a>{' '}
            is a place for members to congregate with fellow academics, industry professionals and
            frens to learn, incubate ideas, inspire eachother, and build. Soon $CLASSX will become
            the #1 place in decentralized ecosystem in metaverse to learn and advance our
            technological society.
          </p>
        </div>
      </section>
      <div style={{ paddingTop: 100 }}>
        <TimeLineFull />
      </div>
    </div>
  );
};

export default Roadmap;
