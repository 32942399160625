import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { ContributerCard } from '../../../components';

// import "./contributers.css";

const Members = ({ bg }) => {
  const data = [
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-jones.jpg',
      name: 'FRANCK JONES',
      description:
        'Author, HCI researcher and edTech developer, Jones looks to hi-jack comics, collectable game mechanics and social trends to help improve technology literacy among adolescents and adults.',
      username: ' @franckjoneswhat',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-anu-founder.png',
        },
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
        },
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-atum-creator.png',
        },
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png',
        },
      ],
    },
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-colbs.jpg',
      name: 'CHRISTIAN COLBERT',
      description:
        'Colbs has been illustrating and designing for nearly two decades, with such professional credits as Spider-Man, Guardians of the Galaxy, and Teenage Mutant Ninja Turtles.',
      username: '@colbert',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-anu-founder.png',
        },

        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-atum-creator.png',
        },
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png',
        },
      ],
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/team-jeremy2.png',
      name: 'Jeremy Gollehon',
      description:
        'Jeremy is a family-first entrepreneur, biohacker, developer, long time NFT collector, co-creator of the #1 NFT wallet viewing app token.art, co-founder of Awesome Labs, and a founding member of the amazing EggsDAO.',
      username: ' @GollyJer',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
        },
      ],
    },
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-bob.jpg',
      name: 'Bob Troia',
      description:
        "Creative technologist, builder, and entrepreneur at the forefront of emerging tech for over two decades. Passionate about biohacking and data-driven self optimization (aka 'Quantified Bob'). Co-founder of Awesome Labs and co-creator of #1 NFT wallet viewing app token.art",
      username: '@BobTroia',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
        },
      ],
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-joe-pancer.jpg',
      name: 'Joe Pancer',
      description:
        'Experienced Dean Of Students with a demonstrated history of working in the education management industry. Skilled in Coaching, Instructional Design, Educational Administration, Fundraising, and Leadership. Strong education professional graduated from Saint Xavier University.',
      username: '@sjp',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png',
        },
      ],
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-leigh-hall.jpg',
      name: 'Leigh Hall',
      description:
        'Leigh A. Hall is a professor at the University of Wyoming where she holds the Wyoming Excellence in Higher Education Endowed Chair in Literacy Education. She taught middle school language arts and social studies in Houston, Texas before returning to graduate school to earn a doctorate in literacy education. Prior to coming to UW, Dr. Hall was at the University of North Carolina for 12 years. ',
      username: '@leighahall',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png',
        },
      ],
    },
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-amrani.jpg',
      name: 'Amrani Dileep',
      description:
        'Amrani holds a bachelors in information technology from Benazir Bhutto Shaheed University Lyari. A dedicated MERN stack developer instructur and student of the game, he helps CLASSX build world class software experiences.',
      username: '@DileepAmrani6',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
        },
      ],
    },
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-mark.jpg',
      name: 'Mark Jonathas',
      description:
        'Mark holds a bachelors in computer science from the University of Depaul, and is a software engineer for a leading crypto hedge fund in Chicago.',
      username: '',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
        },
      ],
    },
    {
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-nick.jpg',
      name: 'Onxx',
      description:
        "Onxx has been involved in the financial markets since he was 19. A powerbroker, avid golfer and founding member of Classx, Onxx has helped the organization raise most of it's capital.",
      username: '@onxx',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-amun-culture.png',
        },
      ],
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/team-brad-wasz.jpg',
      name: 'Bradley J. Wasz',
      description:
        'Brad has founded two billion dollar companies in less than a decade. Raise.com and CPGIO.com. An early investor in $CLASSX, he specializes in eCommerce, sales and marketing.',
      username: '@bradwasz',
      logos: [
        {
          image:
            'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-amun-culture.png',
        },
      ],
    },
  ];

  return (
    <div className="contributers_section" style={{ background: `var(${bg})` }}>
      <br />
      <h1 className="m-0 p-0 burbank text-white text-center member-title">Members</h1>
      <p className="text-white m-0 p-0 pb-3 text-center courier throne-holder-text">
        All team members hold a throne. All throne holders are members. Everything and everyone is
        on-chain.
      </p>
      <br />
      <br />
      <Container>
        <Row>
          {data.map((v) => {
            return (
              <Col key={v.name} xs={12} sm={12} md={6} lg={6}>
                <div className="post-slide" key={v.name}>
                  <ContributerCard
                    name={v.name}
                    summary={v.description}
                    username={v.username}
                    image={v.image}
                    logos={v.logos}
                    className="team-text"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default Members;
