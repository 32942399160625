import './legends.css';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

import { NewWaitList } from '../../../components';

const Legends = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div className="gneeks-characters">
      <div className="col-md-8 ui-layout-column-center">
        <div>
          <h1 className="m-0 p-0 burbank text-white text-center gneeks-title">more frens</h1>
          <br />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 ui-layout-column-center">
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/ZION_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Zion</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/VERO_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Vero</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/LILU_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Lilu</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/CHINO_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Chino</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/QUAN_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Quan</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/NON_0002.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Nōn</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />
                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/LADYBUG_0002.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Ladybug</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="post-slide">
                    <div className="content">
                      <div className="content-overlay" />

                      <img
                        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/HOMELESS_0001.jpg"
                        className="content-image nft-image"
                        alt=""
                      />
                      <div className="content-details fadeIn-bottom">
                        <Link to="." className="link-white">
                          <h3 className="content-title">Houseless Wizard</h3>
                        </Link>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
                <br />
                <br />
                <p className="text-white m-0 p-0 pb-3 text-center courier caption-wider">
                  With book games, meet and collect all of Gou Sokyeo&apos;s incredible frens and
                  discover their unique skillsets.
                </p>
                <br />
                <NewWaitList />
                <br />
                <p className="text-white as-seen-on">As see on:</p>
                <div className="container-fluid partners-row">
                  <div className="row">
                    <div className="col-md-6 ui-layout-column-center">
                      <a href="https://raritysniper.com/nft-drops-calendar" className="text-white">
                        <img alt="" src="assets/rt.png" className="rarity-logo1" />
                      </a>
                    </div>
                    <div className="col-md-6 ui-layout-column-center">
                      <a href="https://raritysniper.com/nft-drops-calendar" className="text-white">
                        <img alt="" src="assets/rarity-sniper-logo.png" className="rarity-logo2" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legends;
