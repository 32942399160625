import './starrin.css';

import React from 'react';

const Legends = () => {
  return (
    <div className="gneeks-legends">
      <div className="col-md-8 ui-layout-column-center">
        <div>
          <h1 className="m-0 p-0 burbank text-white text-center gneeks-title">BOARD CONTROL</h1>
          <br />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 ui-layout-column-center">
                <img
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gods-and-thrones.png"
                  alt=""
                  className="god-thrones"
                />
              </div>
              <br />
              <p className="text-white m-0 p-0 pb-3 text-center courier caption-medium-width">
                Earn or invest in a Throne and use them to upgrade your character with special
                privellages, voting power and the ability to mutate into a god or split into your
                own faction. Use your power to steer the first decentralized board of education.
                Learn more about these mechanics by reading our{' '}
                <a href="/litepaper" className="text-yellow">
                  litepaper.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legends;
