import './popper.css';

import React, { useState } from 'react';

const Popper = ({ children, title, define, des }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {/* // <Tippy
    //   className="popover"
    //   content={
    //     <div>
    //       <p className="m-0 text-white poppin-p">
    //         <b className="text-uppercase">{title}: </b> {define}
    //       </p>
    //       <br />
    //       <p className="m-0 text-white">{des}</p>
    //     </div>
    //   }
    //   placement="top"
    //   animation="scale-subtle"
    //   theme="material"
    //   arrow={false}
    //   duration={200}
    //   delay={[75, 0]}
    //   // distance={8}
    // >
    //   {children}
    // </Tippy> */}

      <div className="pop" onClick={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {children}
        {open ? (
          <div className="pop-content">
            <div>
              <p className="m-0 text-white poppin-p">
                <b className="text-uppercase">{title}: </b> {define}
              </p>
              <br />
              <p className="m-0 text-white poppin-p">{des}</p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Popper;
