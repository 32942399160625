import './card.css';

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const Card = ({ data }) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <div className="board-card" onClick={() => setShow(true)}>
        <img src={data.image} alt="" />
        <h1 className="text-white board-card-title text-center ">{data.name}</h1>
        <p className="andale text-white text-center m-0 ">{data.founder}</p>
        <p className="andale text-white text-center m-0 ">{data.seats}</p>
        <p className="andale text-white text-center m-0 ">{data.votes}</p>
      </div>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        // aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        <div className="modal-dialog-centered p-0" style={{ background: '#181118!important' }}>
          <div className="board-modal-content p-3" style={{ background: '#181118' }}>
            <div className="modal-header-board">
              <button type="button" className="close text-white" onClick={() => setShow(false)}>
                <span aria-hidden="true" style={{ fontSize: '40px' }}>
                  &times;
                </span>
              </button>
            </div>
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
              <h1 className="text-white board-card-title text-center pt-4">{data.name}</h1>
              <img src={data.image} alt="" className="modal-throne-image" />
              <p className="text-white text-center board-modal-text">{data.description}</p>

              <div className="d-flex">
                <div className="col modal-data-text">
                  <p className="m-0 text-white andale">
                    <b>Authorized:</b> {data.data.authorizedSeats}
                  </p>
                  <p className="m-0 text-white andale">
                    <b>Issued:</b> {data.data.seatsIssued}
                  </p>
                  <p className="m-0 text-white andale">
                    <b>Available:</b> {data.data.openSeats}
                  </p>
                </div>
                <div className="col modal-data-text">
                  <p className="m-0 text-white andale">
                    <b>Total Votes:</b> {data.data.authorizedVotes}
                  </p>
                  <p className="m-0 text-white andale">
                    <b>Votes per Unit:</b> {data.data.votesPerSeat}
                  </p>
                  <p className="m-0 text-white andale">
                    <b>Votes Active:</b> {data.data.activeVotes}
                  </p>
                  <p className="m-0 text-white andale">
                    <b>Votes Inactive:</b> {data.data.inActiveVotes}
                  </p>
                </div>
              </div>
              <br />

              <button type="button" className="board-modal-btn" onClick={handleShow1}>
                {data.button === undefined ? 'Acquire' : data.button}
              </button>
              <br />

              <div className="d-flex">
                <div className="col modal-data-text">
                  <p className="m-0 text-white andale">owner list</p>
                </div>
                <div className="col modal-data-text">
                  <p className="m-0 text-white andale">contract address</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={show1}
        onHide={() => handleClose1()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-header">
            <button type="button" className="close text-white" onClick={() => handleClose1()}>
              <span style={{ fontSize: '40px', color: '#fff' }}>&times;</span>
            </button>
          </div>
          <div className="text-white" style={{ textAlign: 'center', margin: '20px auto' }}>
            <h2 className="text-white fw-bold">
              Right now Thrones and Crystals may only be earned. Join our Discord to find out how to
              earn them as grants.
            </h2>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Card;
