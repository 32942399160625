import './hero.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

// import { NewWaitList } from '../../../components';
import { MintingDapp } from '../MintDapp';

const Hero = () => {
  const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <div className="gneeks-hero">
      <div className="col-md-10 ui-layout-column-center">
        <Row items="center">
          <Col xs={12} sm={12} md={6} lg={6} className="p-0 m-0">
            <OwlCarousel className="owl-theme home-owl-character" {...options}>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/2.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/1.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/1.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/3.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/vero/0.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/4.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>

              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/4.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/vero/1.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/12.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>

              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/0.png"
                  style={{
                    maxWidth: '550px',
                    minWidth: '200px',
                    borderBottom: '5px solid #222222',
                  }}
                />
              </div>
            </OwlCarousel>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} className="p-1 m-0">
            <div className="buy-box">
              <MintingDapp />
              {/* <img
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-logo-new.png"
                alt=""
              />
              */}

              <p className="text-white m-0 p-0 hero-paragraphs">
                <p className="gneeks-subtitle">Genius Gods & Geeks</p>
                Join the first PFP and book game club built for computer science enthusiasts. Mint a
                Gneeks PFP and get a lifetime of real world value, including airdrops of our comics,
                print books and the ability to earn $CLASSX Board Thrones and Crystals. In the
                future, use the{' '}
                <a href="/app" className="text-yellow">
                  mobile app
                </a>{' '}
                and{' '}
                <a href="/book-games" className="text-yellow">
                  book games
                </a>{' '}
                to hunt for clues, symbols and elements to level up your characters privileges and
                traits. Earn enough inventory and tranform into a god and unlock more airdrops. Read
                the{' '}
                <a href="/litepaper" className="text-yellow">
                  litepaper
                </a>{' '}
                for the full mechanics.
              </p>
              <p className="text-white m-0 p-0 hero-paragraphs">
                <b>Coming Up:</b> Reserve your spot now for a chance at a reduced-price pre-mint
                spot. Pre-mint begins June 25th. Airdrops will follow after the collection is sold
                out. Check below for more mint details.
              </p>
            </div>
            {/* <NewWaitList isInput /> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Hero;
