import './moments.css';

import React from 'react';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

const Moments = () => {
  const moments = [
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/moment-1.jpg',
      title: 'DARK SCIENCE',
      description:
        'Chino and Vero work on a quantum vortex algorithm for encoding particles that begins to resemble Hawkings 12J paradox of a black hole.',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/moment-2.jpg',
      title: 'THE SKELETON CREW',
      description:
        "Quan (Zion's father), helps Chino (Gou's father) protect a very dangerous science more than 20 years ago.",
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/moment-3.jpg',
      title: 'THE LAST PROTOTYPE',
      description:
        "Gou works on what he thinks will be his last test iteration of the 'Powernet Transcendence Machine'.",
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/moment-4.jpg',
      title: 'ANCIENT WISDOM',
      description:
        "The Gods of Egypt represent what Gou believes is an ill-forgotten wisdom about our origins and where we're going next.",
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/moment-5.jpg',
      title: 'HOTEL NEW YORKER',
      description:
        "Tesla's most important papers end up missing after the FBI raids his hotel after dying alone.",
    },
  ];

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
        stagePadding: 0,
      },
      700: {
        items: 2,
        stagePadding: 0,
      },
      1000: {
        items: 1,
        stagePadding: 350,
        startPosition: 3,
      },
      1200: {
        items: 2,
        stagePadding: 350,
        startPosition: 2,
      },
      // 1600: {
      //   items: 4,
      //   stagePadding: 350,
      //   startPosition: 2,
      // },
    },
  };

  return (
    <div className="moments">
      <Container>
        <p className="text-yellow text-center courier width-shim-80" style={{ fontSize: '19px' }}>
          &bdquo;An incredible blend of fiction and non-fiction history, mystery and intrigue is
          here. For everyone, forever.&bdquo;
        </p>
      </Container>

      <div style={{ marginTop: '2%' }} className="moments_section">
        {moments.length && (
          <OwlCarousel className="owl-theme" {...options}>
            {moments.map((v, i) => {
              return (
                <div className="post-slide moment_slide" key={i}>
                  <div className="_content">
                    <div className="_content-overlay" />
                    <img src={v.image} className="_content-image nft-image" alt="" />
                    <div className="_content-details fadeIn-bottom" style={{ color: 'red' }}>
                      <h1 className="text-white fw-regular m-0 p-0">{v.title}</h1>
                      <p className="text-white" style={{ fontSize: '20px' }}>
                        {v.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
};

export default Moments;
