import './features.css';

import React from 'react';
import ReactPlayer from 'react-player';

const Features = () => {
  return (
    <div className="features_section">
      <h2 className="text-white text-center burbank text-uppercase features_section_title">
        P2E Concept
      </h2>

      <div className="col-md-8 ui-layout-column-center">
        <div className="player-wrapper">
          <ReactPlayer
            url="https://player.vimeo.com/video/533026764?h=546f84e8df"
            className="react-player"
            style={{ width: '100%' }}
            width="100%"
            height="100%"
            controls
          />
        </div>
        <h3 className="text-center andale text-white on_phone_small_text">
          Collect symbols, elements and legends as you learn The Hacker Way. Use your earned items
          to upgrade your PFP&apos;s traits and metadata.
        </h3>

        <div className="app_stores_links">
          <div>
            <a
              href="https://apps.apple.com/us/app/node-chronicles/id1552463165"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white.png"
                width="100%"
              />
            </a>
          </div>
          <div>
            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white-android.png"
              width="100%"
            />
          </div>
        </div>

        <h3 className="text-center andale text-white on_phone_small_text_green">
          Checkout the original proof of concept before it&apos;s too late! A new version is coming
          soon.
        </h3>
      </div>
    </div>
  );
};

export default Features;
