import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, authenticated, ...rest }) => (
  // restricted = false meaning public route
  // restricted = true meaning restricted route
  <Route
    {...rest}
    render={(props) =>
      authenticated && restricted ? <Redirect to="/" /> : <Component {...props} />
    }
  />
);

export default PublicRoute;
