import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const Question = ({ title, info }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <article className="single-question">
      <div>
        <p onClick={() => setExpanded(!expanded)} className="single-question-title andale">
          {title}
        </p>
        <button type="button" className="faqs-btn" onClick={() => setExpanded(!expanded)}>
          {expanded ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </div>
      {expanded && <p className="andale">{info}</p>}
    </article>
  );
};

export default Question;
