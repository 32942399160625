import './samples.css';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const Samples = () => {
  const undergrounds = [
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/comic/comic-sample-1.jpg',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/issue1-preview-1-classx-the-hacker-way.jpg',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/issue2-preview-2-classx-the-hacker-way.jpg',
    },

    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/issue1-preview-2-classx-the-hacker-way.jpg',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/issue1-preview-3-classx-the-hacker-way.jpg',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/issue2-preview-3-classx-the-hacker-way.jpg',
    },
  ];

  const options = {
    startPosition: 1,
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        stagePadding: 0,
      },
      400: {
        items: 1,
        stagePadding: 0,
      },
      600: {
        items: 1,
        stagePadding: 0,
      },
      700: {
        items: 1,
        stagePadding: 150,
      },
      1000: {
        items: 1,
        stagePadding: 350,
      },
    },
  };
  return (
    <div className="samples_section container-fluid">
      <h1 className="stounderSquaredBold text-white text-center samples-heading">#study</h1>
      <p className="text-white text-center board-hero-description courier">
        Level up your technology vocab and literacy skills.
      </p>

      <OwlCarousel className="owl-theme" {...options}>
        {undergrounds.map((v, i) => {
          return (
            <div className="" style={{ padding: '0%' }} key={i}>
              <img src={v.image} className="py-4" alt="" style={{ width: '600px' }} />
            </div>
          );
        })}
      </OwlCarousel>

      <div className="col-md-4 ui-layout-column-center shim-up-20">
        <br />
        <br />
        <p className="text-center p-0 mmrp-regular text-uppercase" style={{ color: '#76aee5' }}>
          &bdquo;This is ridiculously fascinating. Incredbile.&bdquo;
        </p>

        <br />
        <br />
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center" />
        </div>
      </div>
    </div>
  );
};

export default Samples;
