import './hero.css';

import React from 'react';

const Hero = () => {
  return (
    <div className="classx-hero">
      <img
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/class-x-school.png"
        alt=""
      />
      <div className="col-md-6 ui-layout-column-center">
        <p className="text-white m-0 p-0 pb-3 text-center courier essay-text">
          <b>Essay: </b>
          We can solve many problems with web3 and blockchain... We can create products that are
          materially connected, inheritable and verifiable, within a larger network. ClassX is an
          academic institution that works from the inside out, where content owners can publish
          on-chain works, track their use and establish credit like never before. This is the future
          of education.
        </p>
        <br />
      </div>
    </div>
  );
};

export default Hero;
