import './hero.css';
import '../../Proposals/Hero/hero.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Card from '../Card';

const Hero = () => {
  const data = [
    {
      name: 'Nun',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-anu-founder.png',
      founder: 'Founder 5ETH',
      seats: '100 Thrones',
      votes: '(5000 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 76,
        authorizedVotes: '500,000',
        votesPerSeat: 5000,
        activeVotes: '120,000',
        inActiveVotes: '380,000',
      },
      description:
        "Nun, also spelled Nu, oldest of the ancient Egyptian gods and father of Re, the sun god. Nun's name means 'primeval waters,' and he represented the waters of chaos out of which Re-Atum began creation.",
    },
    {
      name: 'Re',
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-re-chair.png',
      founder: 'Chair 1ETH',
      seats: '100 Thrones',
      votes: '(1000 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 91,
        authorizedVotes: '100,000',
        votesPerSeat: 1000,
        activeVotes: '9,000',
        inActiveVotes: '91,000',
      },
      description:
        'Ra was believed to rule in all parts of the created world: the sky, the Earth, and the underworld. He was the god of the sun, order, kings and the sky. Ra was portrayed as a falcon and shared characteristics with the sky-god Horus.',
    },
    {
      name: 'Atum',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-atum-creator.png',
      founder: 'Creator 0.1ETH',
      seats: '1000 Thrones',
      votes: '(250 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 87,
        authorizedVotes: '500,000',
        votesPerSeat: 500,
        activeVotes: '6,500',
        inActiveVotes: '43,500',
      },
      description:
        'Atum was a self-created deity, the first being to emerge from the darkness and endless watery abyss that existed before creation. A product of the energy and matter contained in this chaos, he created his children—the first deities, out of loneliness.',
    },
    {
      name: 'Amun',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-amun-culture.png',
      founder: 'Culture 0.1ETH',
      seats: '1000 Thrones',
      votes: '(250 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 87,
        authorizedVotes: '500,000',
        votesPerSeat: 500,
        activeVotes: '6,500',
        inActiveVotes: '43,500',
      },
      description:
        "Amun, god of the air, was one of the eight primordial Egyptian deities. Amun's role evolved over the centuries; during the Middle Kingdom he became the King of the deities and in the New Kingdom he became a nationally worshipped god. He eventually merged with Ra, the ancient sun god, to become Amun-Ra.",
    },
    {
      name: 'Ptah',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-ptah-craft.png',
      founder: 'Developer 0.1ETH',
      seats: '100 Thrones',
      votes: '(500 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 87,
        authorizedVotes: '500,000',
        votesPerSeat: 500,
        activeVotes: '6,500',
        inActiveVotes: '43,500',
      },
      description:
        'Ptah, also spelled Phthah, in Egyptian religion, creator-god and maker of things, a patron of craftsmen, especially sculptors; his high priest was called “chief controller of craftsmen.” The Greeks identified Ptah with Hephaestus (Vulcan), the divine blacksmith.',
    },
    {
      name: 'Thoth',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png',
      founder: 'Education 0.1ETH',
      seats: '100 Thrones',
      votes: '(500 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 87,
        authorizedVotes: '500,000',
        votesPerSeat: 500,
        activeVotes: '6,500',
        inActiveVotes: '43,500',
      },
      description:
        'Thoth, (Greek), Egyptian Djhuty, in Egyptian religion, a god of the moon, of reckoning, of learning, and of writing. He was held to be the inventor of writing, the creator of languages, the scribe, interpreter, and adviser of the gods, and the representative of the sun god, Re.',
    },
    {
      name: 'Anubis',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-anubis-security.png',
      founder: 'Security 0.1ETH',
      seats: '100 Thrones',
      votes: '(500 votes per throne)',
      data: {
        authorizedSeats: 1000,
        seatsIssued: 100,
        openSeats: 87,
        authorizedVotes: '500,000',
        votesPerSeat: 500,
        activeVotes: '6,500',
        inActiveVotes: '43,500',
      },
      description:
        'He was associated with the funerary cult and the care of the dead, and his later role was as the “conductor of souls.” He was also reputed to be the inventor of embalming, an art he first employed on the corpse of Osiris.',
    },
  ];

  const advisors = [
    {
      name: 'Mut',
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-mut.png',
      founder: 'Family 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        "Mut was considered a primal deity, associated with the primordial waters of Nu from which everything in the world was born. The name Mut means 'mother,' and her role was that of an older woman among the gods. She was associated with the uraeus (rearing cobra), lionesses, and royal crowns. ",
    },
    {
      name: 'Maat',
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-maat.png',
      founder: 'Justice 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Maat, also spelled Mayet, in ancient Egyptian religion, the personification of truth, justice, and the cosmic order. The daughter of the sun god Re, she was associated with Thoth, god of wisdom.',
    },
    {
      name: 'Hathor',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-hathor.png',
      founder: 'Love 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Hathor was one of the forty-two state gods and goddesses of Egypt, and one of the most popular and powerful. She was goddess of many things: love, beauty, music, dancing, fertility, and pleasure.',
    },
    {
      name: 'Sekhmet',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-sekhmet.png',
      founder: 'Health 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Sekhmet, also spelled Sakhmet, in Egyptian religion, a goddess of war and the destroyer of the enemies of the sun god Re. Sekhmet was associated both with disease and with healing and medicine.',
    },
    {
      name: 'Bastet',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-bastet.png',
      founder: 'Home 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Bastet was the goddess of protection, pleasure, and the bringer of good health. She had the head of a cat and a slender female body. Bastet was the daughter of Ra, sister of Sekhmet, the wife of Ptah, and the mother of Mihos.',
    },
    {
      name: 'Shu',
      image: 'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-shu.png',
      founder: 'Environment 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Shu, in Egyptian religion, god of the air and supporter of the sky, created by Atum by his own power, without the aid of a woman. Shu and his sister and companion, Tefnut (goddess of moisture), were the first couple of the group of nine gods called the Ennead of Heliopolis.',
    },
    {
      name: 'Tefnut',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-tefnut.png',
      founder: 'Elements 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        'Tefnut was a goddess of rain, water, and moisture. She was also at times a lunar deity, associated with the cycles and power of the moon. As a water goddess in a desert civilization, Tefnut was more directly responsible for maintaining life than nearly any other deity.',
    },
    {
      name: 'Khansu',
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-khansu.png',
      founder: 'Nature 0.01ETH',
      seats: '10,000 Crystals',
      votes: '(5 votes per crystal)',
      data: {
        authorizedSeats: 100000,
        seatsIssued: 1000,
        openSeats: 1000,
        authorizedVotes: '500,000',
        votesPerSeat: 5,
        activeVotes: '0',
        inActiveVotes: '500,000',
      },
      description:
        "Khonsu was responsible for the fertility of not just humans, but also livestock and crops. He was also associated with time, like the moon is, and one of his names meant the ''decider of the lifespan.''",
    },
  ];
  return (
    <div className="board-hero proposals-hero">
      <div className="col-md-7 ui-layout-column-center">
        <br />
        <br />
        <p className="text-center proposals-hero-sub-title">Welcome to the</p>
        <h1 className="m-0 p-0 burbank text-white text-center proposals-hero-title">The Board</h1>
        <p className="text-white text-center proposals-hero-description">
          Earn or invest in Thrones & Crystals for stake, mutation/split rights, and say-so within
          the Gneeks.org decentralized ecosystem.
        </p>

        <div className="under-co">
          <p>
            <b>Note:</b> 70% of all proceeds generated from Thrones and Crystals will be deposited
            into the board vault. Take heed to the roles listed below for ques on how to earn grants
            within the Gneek faction. Consistently demonstrated contribution related to each of the
            listed asset classes are key.
            <br />
            <br />
            <b>Status:</b> Governance features are in development. In the meantime, checkout our{' '}
            <a href="/litepaper">litepaper</a> to read up on the proposed mechanics Thrones and
            Crystals.
          </p>
        </div>
      </div>

      <br />
      <div className="col-md-8 ui-layout-column-center">
        <Row>
          {data.map((v, i) => {
            return (
              <Col xs={12} sm={12} md={6} lg={4} key={i}>
                <Card data={v} />
              </Col>
            );
          })}
        </Row>

        <hr className="text-white m-5" />
        <br />
        <p className="text-center board-hero-sub-title">with energy from the</p>
        <h1 className="m-0 p-0 stounderSquaredBold text-white text-center board-hero-title">
          Advisors
        </h1>

        <Row>
          {advisors.map((v, i) => {
            return (
              <Col xs={12} sm={12} md={6} lg={4} key={i}>
                <Card data={v} />
              </Col>
            );
          })}
        </Row>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Hero;
