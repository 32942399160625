import './timeline.css';

import React from 'react';
import { Link } from 'react-router-dom';

const TimeLine = () => {
  return (
    <div className="timeline-container">
      <h1 className="m-0 p-0 burbank text-white text-center timeline-hero-title">
        Historical <br />
        Timeline
      </h1>
      <br />
      <br />
      <img
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/class-x-logo-white.png"
        alt=""
        className="logo"
      />

      <div className="timeline">
        <ul>
          <li>
            <div className="time-content">
              <h3>Focus Groups & User Research</h3>
              <p className="timeline_description">
                Research team begins working with focus groups to understand the state of technology
                literacy in various communities. Ethnographic studies are performed with hundreds of
                participants.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Feb 2019</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>Writing Complete</h3>
              <p className="timeline_description">
                Four books written along with a complete EECS curriculum. A periodic table of
                technology elements known as
                <Link to="/gaming" className="link">
                  AxiomX
                </Link>
                is established
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Oct 2020</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>First Edition Print Release</h3>
              <p className="timeline_description">
                Node Chronicles Inc. prints and ships 1,500 first edition books world-wide as part
                of an experiment. The book is sold on Amazon and at
                <a
                  href="https://nodechronicles.com/"
                  className="link"
                  target="_blank"
                  rel="noreferrer"
                >
                  nodechronicles.com.
                </a>{' '}
                The buy-one-give-one program sends 750 FREE books to underserviced communities.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Oct 2021</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>App Released</h3>
              <p className="timeline_description">
                An expiramental app is released for
                <Link
                  to={{ pathname: 'https://nodechronicles.com/pages/app' }}
                  target="_blank"
                  className="link"
                >
                  iOS and Android
                </Link>{' '}
                that allows readers to scan symbols found in book 1. The feature unlocks extended
                plots and knowledge. Readers begin to interact with digital assets and AxiomX. Human
                computer interaction information is gathered for V2.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Oct 2021</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>Class X Initiative Begins</h3>
              <p className="timeline_description">
                Node Chronicles realizes there is an opportunity to work closely with thousands of
                passionate people in education. Sees ClassX as an ecosystem that can benefit from
                blockchain, smart contracts and governance tokens.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Dec 2021</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>IRB Granted</h3>
              <p className="timeline_description">
                University of Wyoming College of Education Professor Leigh Hall obtians board
                approval for independent research against Class X usage data to determine if
                products have impact on adolescent literacy and motivation.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Jan 2022</h4>
            </div>
          </li>

          <li>
            <div className="time-content">
              <h3>Awesome Labs Join Dev Team</h3>
              <p className="timeline_description">
                Jeremy Gollehon and Bob Troia of
                <a href="https://token.art" target="_blank" className="link" rel="noreferrer">
                  Token.art
                </a>
                and
                <a
                  href="https://isjustawesome.com/"
                  target="_blank"
                  className="link"
                  rel="noreferrer"
                >
                  Awesome Labs
                </a>
                partner with Class X and Node Chronicles to offer NFTs and Smart Contracts to a
                10,000 member ecosystem.
              </p>
              <p className="status">Stage Complete</p>
            </div>
            <div className="time">
              <h4>Feb 2022</h4>
            </div>
          </li>

          <div style={{ clear: 'both' }} />
        </ul>
      </div>
      <br />
      <br />
    </div>
  );
};

export default TimeLine;
