import './card.css';

import React, { useState } from 'react';

const Card = () => {
  const [expand, setExpand] = useState(false);
  return (
    <div className="prop-card">
      <div className="throne-image">
        <img
          src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-thoth-teach.png"
          alt=""
        />
      </div>
      <div className="px-4 prop-card-content" style={{ flex: 1 }}>
        <h1 className="prop-card-title fw-medium">Prop 2 - Authorize curriculum api development</h1>
        <p className="prop-card-description py-4">
          941 ETH has been collected in the $CLASSX Vault. <br /> Use 60 ETH to fund P2E choose your
          own adventure experience.
        </p>

        {expand ? (
          <div>
            <p className="prop-card-description pb-4">
              <b> PROJECT OVERVIEW :</b> The P2E casual strategy game is a choose your own adventure
              conceptualized by Franck Jones. The objective is develop one of the worlds most
              engaging educational game that behaves like a storymode in a video game. Users can
              play with their PFP, or lease a staked PFP, and acquire $CLASSX tokens for streaking
              and making good choices in the game.
            </p>
            <p className="prop-card-description pb-4">
              <b> PROTOTYPE </b> The prototype for the application can be found here :
              XYZ.com/prototypel
            </p>
            <p className="prop-card-description pb-4">
              <b>TIMELINE :</b> Alpha release, June 2022 (6 months)
            </p>
            <p className="prop-card-description pb-4">
              <b>TEAM :</b>
              @franckjones, @kwexoo, @franckjones, @jeremygollehan, @btroia, @marajon,
              @johnrichards, @datapimp, @amranipak, @shashankgupta, @colbert 2,
            </p>
            <p className="prop-card-description pb-4">
              <b>Comments :</b>
            </p>

            <p className="prop-card-description pb-4">
              <b> FranckJones :</b>
              Really excited about this project. Everyone on the team seems super passionate about
              doing this.
            </p>

            <p className="prop-card-description pb-4">
              <b> Colbert :</b>
              The prototype is really amazing team. Excited to help develop the art for this
              project.
            </p>
          </div>
        ) : (
          ''
        )}
        {expand ? (
          <div className="d-flex">
            <button type="button" className="prop-card-btn" onClick={() => setExpand(false)}>
              Collapse
            </button>
          </div>
        ) : (
          <div className="d-flex">
            <button type="button" className="prop-card-btn" onClick={() => setExpand(true)}>
              Expand
            </button>
            <button type="button" className="prop-card-btn">
              Vote
            </button>
            <button type="button" className="prop-card-btn">
              Comments (35)
            </button>
          </div>
        )}
      </div>
      <div className="votes">
        <div className="d-flex">
          <div className="prop-vote-card prop-dis-like">
            <p className="vote-count">35</p>
            <p className="vote-type">no</p>
          </div>
          <div className="prop-vote-card prop-like">
            <p className="vote-count">51</p>
            <p className="vote-type">yes</p>
          </div>
        </div>
        <p className="prop-vote-ends-in">Voting ends in 2 days 11 hours 35 min</p>
        {expand ? (
          <div>
            <div className="d-flex align-items-center">
              <div className="user-icon-liked">yes</div>
              <p>@franckjones (reason)</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="user-icon-liked">yes</div>
              <p>@franckjones (reason)</p>
            </div>{' '}
            <div className="d-flex align-items-center">
              <div className="user-icon-dis-liked">no</div>
              <p>@franckjones (reason)</p>
            </div>{' '}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
