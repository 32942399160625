import './buyArea.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

import { NewWaitList } from '../../../components';

const Hero = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      700: {
        items: 1,
      },
      850: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  return (
    <div className="gneeks-buy-area">
      <div className="col-md-10 ui-layout-column-center">
        <Row className="align-items-center">
          <Col xs={12} sm={12} md={6} lg={6}>
            <OwlCarousel className="owl-theme" {...options}>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-variant-1.png"
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-sample-4_.png"
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-sample-3_.png"
                />
              </div>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-sample-2_.png"
                />
              </div>
            </OwlCarousel>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="book-content-area">
              <h1 className="m-0 p-0 stounderSquaredBold text-white  gneeks-buy-area-title">
                Book Games
              </h1>
              <p className="gneeks-buy-area-subtitle p-0">
                &bdquo;A real-world adventure so incredible, a graphic novel needed to be
                made.&bdquo;
              </p>
              <br />
              <p className="text-white m-0 p-0 pb-3">
                After finding Tesla&apos; missing papers, Gou Sokyeo must weigh the pros and cons of
                messing with dangerous science. One wrong move could spell disaster.
                <br />
                <br />
                Book one, Into the Ether, embodies the evocative utility we find in technology.
                Unearth contextual clues, explore meta-level ideals such as: The Hacker Way and earn
                the ability to send change into the future.
                <br />
                <br />
                And never forget... Only YOU have have the power to change the world.
                <br />
              </p>
              <p className="text-white m-0 p-0 pb-3">
                <br />
                <b>NFT Edition</b>
                <br />
                Gain the benefit of Airdrops that extend your books value. Play your options right
                and earn a throne (for access to the board). Use your privileges to build greater
                stake within the Gneeks.org ecosystem and earn $CLASSX.
              </p>
              <br />
              <p className="text-white m-0 p-0 pb-3">
                <b>Print Edition</b>
                <br />
                Collect book one as a pefect bound oversized 52 page comic. With each book you buy,
                get 1 random scratch ticket, with odds for winning a throne, crystal, 1/1 cover and
                more.
              </p>
              <br />
              <br />
              <div className="buy-area-outline-btn-box gap-2">
                <div className="mt-3">
                  <NewWaitList />
                </div>
                <div className="mt-3">
                  <button type="button" className="pushable-white">
                    <a
                      href="https://gneeks.myshopify.com/collections/all"
                      target="_blank"
                      className="front-white _burbank"
                      rel="noreferrer"
                    >
                      Pre-Order Print
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Hero;
