import './playin.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { NewWait } from '../../../components';

const PlayIn = () => {
  return (
    <div className="play-in">
      <h1 className="m-0 p-0 stounderSquaredBold text-white text-center play-in-title">
        On-Chain Academics
      </h1>
      <p className="play-in-subtitle p-0">
        Patents, whitepapers, frameworks, poems, datasets... what if these were all NFTs? What if
        Teslas papers lived on the blockchain? What if Ada Lovelace&apos;s poems about computers
        were published on Ethereum? What if scientific data was sent into the ether, and their
        origins were linked and verifiable? What if future products that inherited from critical
        datasets were all traceable? As academics, how much more should we question ourselves before
        we realize this day is coming? The writing is on the wall: #classx
      </p>
      <br />

      <div className="">
        <div className="col-md-10 ui-layout-column-center">
          <Row className="align-items-center">
            <Col xs={12} sm={12} md={6} lg={6}>
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/legends-classx.png"
                />
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="class-copy-body">
                <p className="text-white m-0 p-0 pb-3">
                  <b>What is ClassX?</b>
                  <br />
                  ClassX is the first on-chain academic ecosystem implemented as a massive community
                  role playing game, with real-world outcomes. It&apos;s been designed to guide us
                  all towards the end goal mentioned above.
                </p>
                <p className="text-white m-0 p-0 pb-3">
                  <b>When will it be ready?</b>
                  <br />
                  It&apos;s all happening right now. Your first action is joining the waitlist. Your
                  next will be to free mint the Gneeks genesis NFT, to hold your spot. From there,
                  book games begin, the gates will open, and the disruption alone will send us.
                </p>
                <p className="text-white m-0 p-0 pb-3">
                  <b>What can I do now?</b>
                  <br />
                  Spread the word with innovative academics passionate about education. Bring in the
                  big thinkers, the PhD&apos;s, the influencers, the thrill seekers, anyone who
                  believes that higher intelligence is our only hope. Also, read the{' '}
                  <a href="/litepaper" className="uline">
                    Litepaper
                  </a>
                  .
                </p>
                <br />
                <div className="buy-area-outline-btn-box">
                  <NewWait title="Join Waitlist" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PlayIn;
