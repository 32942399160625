import './Characters.css';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { CharactersComponent, Footer, LegendsComponents, Nav, SemiRares } from '../../components';

const Characters = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div
      style={{
        backgroundColor: 'var(--bs-node-dark)',
      }}
    >
      <Nav path="characters" />
      <main role="main">
        <div className="characters-section">
          <CharactersComponent />
          <SemiRares />
          <LegendsComponents />
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Characters;
