import './hero.css';

import React, { useRef, useState } from 'react';
import { RiArrowLeftSFill, RiArrowRightSFill } from 'react-icons/ri';

import Card from '../Card';

const Hero = () => {
  const scrl = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const data = [
    {
      tabName: 'Founder',
      data: true,
    },
    {
      tabName: 'Chair',
      data: true,
    },
    {
      tabName: 'Creative',
      data: false,
    },
    {
      tabName: 'Culture',
      data: false,
    },
    {
      tabName: 'Build',
      data: true,
    },
    {
      tabName: 'Tech',
      data: true,
    },
    {
      tabName: 'Security',
      data: false,
    },
    {
      tabName: 'Family',
      data: false,
    },
    {
      tabName: 'Culture',
      data: true,
    },
  ];
  // Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  return (
    <div className="proposals-hero">
      <div className="col-md-7 ui-layout-column-center">
        <br />
        <br />
        <p className="text-center proposals-hero-sub-title">Community Developed</p>
        <h1 className="m-0 p-0 burbank text-white text-center proposals-hero-title">Proposals</h1>
        <p className="text-white text-center proposals-hero-description">
          This web3 challenge belongs to everyone.
        </p>
        <div className="under-co">
          <p>
            This feature is not ready. Checkout our <a href="/litepaper">litepaper</a> for an
            overview on poposals.
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className="col-md-8 ui-layout-column-center">
        <div className="d-flex align-items-center">
          {scrollX !== 0 && (
            <button type="button" className="prev" onClick={() => slide(-50)}>
              <RiArrowLeftSFill className="arrow-btn" />
            </button>
          )}
          <ul className="proposals-categories" ref={scrl} onScroll={scrollCheck}>
            {data.map((v, i) => {
              return (
                <li key={i} onClick={() => setActiveTab(i)}>
                  <button className={activeTab === i ? 'cat-active' : ''}>{v.tabName}</button>
                </li>
              );
            })}
          </ul>
          {!scrolEnd && (
            <button type="button" className="next" onClick={() => slide(+50)}>
              <RiArrowRightSFill className="arrow-btn" />
            </button>
          )}
        </div>
        <br />
        {data.map((v, i) => {
          if (!activeTab === i) return '';

          return (
            <div key={data.tabName}>
              {v.data ? (
                <Card />
              ) : (
                <h1 className="fw-medium text-center text-white py-4">
                  No {v.tabName} Proposals Yet
                </h1>
              )}
            </div>
          );
        })}
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Hero;
