import 'rsuite/dist/rsuite.min.css';
import './dashNav.css';

import { gql } from '@apollo/client';
import {
  faArrowDown,
  faBars,
  faGamepad,
  faInfo,
  faKey,
  faTimes,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { client } from '../../apollo/client';

const GET_CHARACTERS = gql`
  query {
    characters(where: { legend: { _eq: false } }) {
      name
      id
      portrait
    }
  }
`;

const GET_LEGENDS = gql`
  query {
    characters(where: { legend: { _eq: true } }) {
      name
      id
      portrait
    }
  }
`;

const GET_ELEMENTS = gql`
  query {
    elements {
      name
      image
      mp4
    }
  }
`;

const GET_MOMENTS = gql`
  query {
    moments {
      name
      cover_image
      summary
    }
  }
`;

const Navbar = ({ children, navClose }) => {
  const [characters, setCharacters] = useState([]);
  const [legends, setLegends] = useState([]);
  const [elements, setElements] = useState([]);
  const [moments, setMoments] = useState([]);

  useEffect(() => {
    const getCharacters = async () => {
      const result = await client.query({
        query: GET_CHARACTERS,
      });
      setCharacters(result.data.characters);
    };
    getCharacters();

    const getLegends = async () => {
      const result = await client.query({
        query: GET_LEGENDS,
      });
      setLegends(result.data.characters);
    };
    getLegends();

    const getElements = async () => {
      const result = await client.query({
        query: GET_ELEMENTS,
      });
      setElements(result.data.elements);
    };
    getElements();

    const getMoments = async () => {
      const result = await client.query({
        query: GET_MOMENTS,
      });
      setMoments(result.data.moments);
    };
    getMoments();
  }, []);

  const characterRoutes = characters.map((v) => {
    return {
      name: v.name,
      route: `/character/${v.name.replaceAll(' ', '-').toLowerCase()}`,
    };
  });
  const legendsRoutes = legends.map((v) => {
    return {
      name: v.name,
      route: `/character/${v.name.replaceAll(' ', '-').toLowerCase()}`,
    };
  });

  const elementsRoutes = elements.map((v) => {
    return {
      name: v.name,
      route: `/element/${v.name.replaceAll(' ', '-').toLowerCase()}`,
    };
  });

  const momentsRoutes = moments.map((v) => {
    return {
      name: v.name,
      route: `/story/${v.name.replaceAll(' ', '-').toLowerCase()}`,
    };
  });

  const [index, setIndex] = useState();
  const [open, setOpen] = useState(navClose);
  const [showNav, setShowNav] = useState(true);
  const { path } = useRouteMatch();

  const nav = [
    {
      name: 'Storymode',
      route: '/storymode',
      nested: true,
      items: momentsRoutes,
    },

    {
      name: 'Characters',
      route: '/character',
      nested: true,
      items: characterRoutes,
    },

    {
      name: 'Legends',
      route: '/legends',
      nested: true,
      items: legendsRoutes,
    },

    {
      name: 'Elements',
      route: '/element',
      nested: true,
      items: elementsRoutes,
    },
    {
      name: 'Clues',
      route: '/clues',
      nested: true,
      items: [
        {
          name: 'Building Chemistry',
          route: '/clues',
        },
        {
          name: 'Your Power',
          route: '/clues',
        },
        {
          name: 'Knowledge Unlocks',
          route: '/clues',
        },
        {
          name: 'Leveling Up',
          route: '/clues',
        },
      ],
    },
  ];

  const openAcc = (i) => {
    if (i === index) {
      setIndex(-1);
    } else {
      setIndex(i);
    }
  };

  return (
    <div style={{ position: 'fixed' }}>
      <div className="dash_navbar">
        <Link to="/" className="dash_navbar_logo dekstop">
          <img
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/class-x-logo-white.png"
            alt=""
            className="_logo"
          />
        </Link>
        <div
          className="mobile"
          style={{ padding: '2%', cursor: 'pointer' }}
          onClick={() => setShowNav(!showNav)}
          onKeyDown={() => setShowNav(!showNav)}
          role="button"
        >
          <FontAwesomeIcon icon={faBars} className="dash_nav_bar_icon" />
        </div>

        <ul className="dash_nav-menu">
          <li className="dash_nav_item">
            <Link className="nav-links" to=".">
              <FontAwesomeIcon icon={faUserCircle} className="dash_nav_icon" />
            </Link>
          </li>
          <li className="dash_nav_item dekstop">
            <label className="text-white link_text">Ethereum Network L1</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <label className="text-white coin_value">0 Eth</label>
              <div className="coin_value_box">
                <div>
                  <img
                    src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/coin.png"
                    alt=""
                    style={{
                      maxHeight: '30px',
                      maxWidth: '30px',
                    }}
                  />
                </div>
                <label className="coin_value yellow_text">2,399,475 Classx</label>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div style={{ display: 'flex', width: '100vw' }}>
        {showNav ? (
          <div className="side_bar">
            <ul style={{ padding: '10px' }}>
              <li className="side_nav_item">
                <Link to="/home">
                  <div
                    style={{
                      backgroundColor: '#cf4e52',
                    }}
                    className="side_nav_icon_box"
                  >
                    <FontAwesomeIcon icon={faTimes} style={{ color: '#fff', fontSize: '20px' }} />
                  </div>
                  <p className="text-white link_text">Home</p>
                </Link>
              </li>
              <li className="side_nav_item">
                <Link to="/play">
                  <div
                    style={{
                      backgroundColor: '#64dd65',
                    }}
                    className="side_nav_icon_box"
                  >
                    <FontAwesomeIcon icon={faGamepad} style={{ color: '#fff', fontSize: '20px' }} />
                  </div>
                  <p className="text-white link_text">Play</p>
                </Link>
              </li>

              <li className="side_nav_item" onClick={() => setOpen(!open)}>
                {/* <Link to="/characters/nikola-tesla"> */}
                <div
                  style={{
                    backgroundColor: '#8547a4',
                  }}
                  className="side_nav_icon_box"
                >
                  <FontAwesomeIcon icon={faUser} style={{ color: '#fff', fontSize: '20px' }} />
                </div>
                <p className="text-white link_text">Collect</p>
                {/* </Link> */}
              </li>
              <li className="side_nav_item">
                <Link to="/unlocks">
                  <div
                    style={{
                      backgroundColor: '#5794f1',
                    }}
                    className="side_nav_icon_box"
                  >
                    <FontAwesomeIcon icon={faKey} style={{ color: '#fff', fontSize: '20px' }} />
                  </div>
                  <p className="text-white link_text">Unlocks</p>
                </Link>
              </li>
              <li className="side_nav_item">
                <Link to="/about">
                  <div
                    style={{
                      backgroundColor: '#e8a642',
                    }}
                    className="side_nav_icon_box"
                  >
                    <FontAwesomeIcon icon={faInfo} style={{ color: '#fff', fontSize: '20px' }} />
                  </div>
                  <p className="text-white link_text">About</p>
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
        {open ? null : (
          <div className="sub_side_nav">
            <ul>
              {nav.map((v, i) => {
                return (
                  <>
                    <li
                      className={`sub_nav_item ${
                        path.toLowerCase() === v.route ? 'sub_nav_item_active' : ''
                      }`}
                      key={i}
                      onClick={() => openAcc(i)}
                    >
                      <Link to={v.route} style={{ textDecoration: 'none' }}>
                        <div className="sub_item_header">
                          {v.name}
                          <label style={{ backgroundColor: 'transparent' }}>
                            <FontAwesomeIcon icon={faArrowDown} />
                          </label>
                        </div>
                      </Link>
                    </li>
                    {v.nested && path.toLowerCase() === v.route.toLowerCase() ? (
                      <div className="sub_item_context">
                        <ul>
                          {v.items.map((v2, i2) => {
                            return (
                              <li key={i2}>
                                <input
                                  type="checkbox"
                                  checked
                                  style={{ backgroundColor: 'yellow' }}
                                />
                                <Link
                                  to={`${v2.route}`}
                                  style={{
                                    color: `${i2 === 0 ? 'yellow' : '#fff'}`,
                                  }}
                                >
                                  {v2.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </ul>
          </div>
        )}
        <div style={{ flex: 1 }} className="main_container">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
