import './notificationBar.css';

import React from 'react';
import NewsTicker from 'react-advanced-news-ticker';

const NotificationBar = ({ bg, color, text }) => {
  return (
    <div className="notification-bar" style={{ backgroundColor: bg }}>
      <NewsTicker
        speed={200}
        duration={4000}
        autoStart
        pauseOnHover
        style={{ listStyle: 'none', padding: '5px 0' }}
      >
        {text.map((v, i) => {
          return (
            <div key={i} className="m-0">
              <p className="p-3 m-0 announce-news-wrapper" style={{ color }}>
                {v}
              </p>
            </div>
          );
        })}
      </NewsTicker>
    </div>
  );
};

export default NotificationBar;
