import 'rsuite/dist/rsuite.min.css';
import './nav.css';
import '../styles.css';

import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer } from 'rsuite';

import NewConnect from '../NewConnect';

const NewNav = ({ isDoc }) => {
  const [open, setOpen] = React.useState(false);
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    setDropdown(true);
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  return (
    <>
      <Drawer
        size="xs"
        open={open}
        onClose={() => setOpen(false)}
        classPrefix="drawer"
        style={{
          padding: '0px !important',
          display: 'block',
          width: '100%',
          height: '100vh',
          maxWidth: '400px',
          minWidth: '300px',
          background: 'transparent',
        }}
      >
        <Drawer.Body
          style={{
            padding: '0px !important',
            height: '100vh',
            backgroundColor: 'var(--theme-purple)',
            // backgroundImage:
            //   "url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nav-bg.png')",
            // backgroundSize: "100% 100%",
            position: 'relative',
          }}
        >
          <div className="drawer_close_btn">
            <button type="button" className="text-white">
              <FontAwesomeIcon
                icon={faTimes}
                className="dash_nav_bar_icon"
                onClick={() => setOpen(false)}
              />
            </button>
          </div>
          <div className="drawer_body">
            <img
              className="demi-god-image"
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gou-demigod.png"
              alt=""
            />
            <p className="text-white text-center andale py-2">
              The first on-chain <br />
              academic ecosystem.
            </p>
            <ul className="theme-nav-drawer-list">
              <li className="theme-nav-drawer-list-item">
                <Link to="/book-games" className="d-flex align-items-center theme-nav-drawer-link">
                  <img
                    src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-books.png"
                    alt=""
                    style={{
                      maxWidth: '45px',
                      width: '100%',
                      display: 'inline',
                    }}
                  />
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <label
                      style={{
                        fontSize: '25px',
                        padding: 10,
                        cursor: 'pointer',
                      }}
                      className="text-white stounderSquaredBold-mini"
                    >
                      {' '}
                      Book Games
                    </label>
                  </div>
                </Link>
              </li>

              <li className="theme-nav-drawer-list-item">
                <Link to="/classx" className="d-flex align-items-center theme-nav-drawer-link">
                  <img
                    src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-school.png"
                    alt=""
                    style={{
                      maxWidth: '60px',
                      width: '100%',
                      display: 'inline',
                    }}
                  />
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <label
                      style={{
                        fontSize: '25px',
                        padding: 0,
                        cursor: 'pointer',
                      }}
                      className="text-white stounderSquaredBold-mini"
                    >
                      $Classx
                    </label>
                  </div>
                </Link>
              </li>
              <li className="theme-nav-drawer-list-item  sub-list-first">
                <Link to="/board" className="d-flex align-items-center theme-nav-drawer-link">
                  <div className="sub-list">
                    <img
                      style={{ width: '40px', display: 'inline-block' }}
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-scale.png"
                      alt=""
                    />{' '}
                  </div>
                  <p className="text-uppercase text-white them-nav-drawer-link-text">The Board</p>
                </Link>
              </li>
              <li className="theme-nav-drawer-list-item">
                <Link to="/proposals" className="d-flex align-items-center theme-nav-drawer-link">
                  <div className="sub-list">
                    <img
                      style={{ width: '40px', display: 'inline-block' }}
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-classx.png"
                      alt=""
                    />{' '}
                  </div>
                  <p className="text-uppercase them-nav-drawer-link-text">Proposals</p>
                </Link>
              </li>
              <li className="theme-nav-drawer-list-item">
                <Link
                  to={{ pathname: 'https://discord.gg/36PmUWbGDc' }}
                  target="_blank"
                  className="d-flex align-items-center theme-nav-drawer-link"
                >
                  <div className="sub-list">
                    <img
                      style={{ width: '40px', display: 'inline-block' }}
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-discord-emoji2.png"
                      alt=""
                    />{' '}
                  </div>
                  <p className="text-uppercase them-nav-drawer-link-text">Discussion</p>
                </Link>
              </li>
              <li className="theme-nav-drawer-list-item">
                <Link
                  to={{ pathname: 'https://twitter.com/gneeksapp' }}
                  target="_blank"
                  className="d-flex align-items-center theme-nav-drawer-link"
                >
                  <div className="sub-list">
                    <img
                      style={{ width: '40px', display: 'inline-block' }}
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-twitter-emoji.png"
                      alt=""
                    />{' '}
                  </div>
                  <p className="text-uppercase them-nav-drawer-link-text">Public Opinion</p>
                </Link>
              </li>
              <li className="theme-nav-drawer-list-item">
                <Link
                  to={{ pathname: 'https://www.instagram.com/godsandgeeks/' }}
                  target="_blank"
                  className="d-flex align-items-center theme-nav-drawer-link"
                >
                  <div className="sub-list">
                    <img
                      style={{ width: '40px', display: 'inline-block' }}
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-instagram-proof.png"
                      alt=""
                    />{' '}
                  </div>
                  <p className="text-uppercase them-nav-drawer-link-text">Social Proof</p>
                </Link>
              </li>
            </ul>
            <br />
            {/* <ul className="theme-nav-drawer-list">
              <li className="theme-nav-drawer-list-item wait-button-margin">
                <NewConnect />
              </li>
            </ul> */}
          </div>
          <div className="theme-nav-drawer-btm">
            <ul className="theme-nav-drawer-btm-list">
              <li>
                <Link to="/team" className="text-white">
                  Members
                </Link>
              </li>
              <li>
                <Link to="/roadmap" className="text-white">
                  Roadmap
                </Link>
              </li>
              <li>
                <Link to="/litepaper" className="text-white">
                  Litepaper
                </Link>
              </li>
            </ul>
          </div>
          <img
            className="menu-logo-footer"
            alt=""
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/footer-logos.png"
          />
        </Drawer.Body>
      </Drawer>
      <div className="theme-nav">
        <div className=" d-flex align-items-center">
          <div className="d-flex align-items-center" style={{ flex: 1 }}>
            <div className="">
              <Link to="/" className="link d-flex align-items-center">
                <img
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-logo-trans.png"
                  alt=""
                  className="theme-nav-logo"
                />
              </Link>
            </div>
            <div className="theme-nav-left">
              <ul className="theme-nav-list d-flex align-items-center">
                <li className="theme-nav-list-item">
                  <Link to="/book-games" className="d-flex align-items-center theme-nav-link">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-books.png"
                      alt=""
                      style={{ width: '60px' }}
                    />

                    <label
                      style={{
                        fontSize: '25px',
                        padding: 0,
                        cursor: 'pointer',
                      }}
                      className="text-white stounderSquaredBold-mini"
                    >
                      {' '}
                      Book Games
                    </label>
                  </Link>
                </li>
                <li
                  className="theme-nav-list-item"
                  style={{ position: 'relative' }}
                  onMouseEnter={() => onMouseEnter()}
                  onMouseLeave={() => onMouseLeave()}
                >
                  <p className="link d-flex align-items-center justify-start theme-nav-link">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-school.png"
                      alt=""
                      style={{ width: '70px' }}
                    />
                    <p
                      className="text-white stounderSquaredBold-mini"
                      style={{ fontSize: '25px' }}
                      onClick={() => {
                        window.location.href = '/classx';
                      }}
                    >
                      $ClassX
                    </p>
                  </p>
                  {dropdown ? (
                    <div
                      className="theme-dropdown-menu"
                      onMouseEnter={() => onMouseEnter()}
                      onMouseLeave={() => onMouseLeave()}
                    >
                      <div className="theme-dropdown-box">
                        <Link to="/board" className="d-flex align-items-center theme-dropdown-link">
                          <div>
                            <img
                              style={{ width: '40px', display: 'inline-block' }}
                              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-scale.png"
                              alt=""
                            />{' '}
                          </div>
                          <p className="text-uppercase them-nav-link-text">The Board</p>
                        </Link>
                      </div>
                      <div className="theme-dropdown-box">
                        <Link
                          to="/proposals"
                          className="d-flex align-items-center theme-dropdown-link"
                        >
                          <div>
                            <img
                              style={{ width: '40px', display: 'inline-block' }}
                              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-classx.png"
                              alt=""
                            />{' '}
                          </div>
                          <p className="text-uppercase them-nav-link-text">Proposals</p>
                        </Link>
                      </div>

                      <div className="theme-dropdown-box">
                        <Link
                          to={{ pathname: 'https://discord.gg/36PmUWbGDc' }}
                          target="_blank"
                          className="d-flex align-items-center theme-dropdown-link"
                        >
                          <div>
                            <img
                              style={{ width: '40px', display: 'inline-block' }}
                              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-discord-emoji2.png"
                              alt=""
                            />{' '}
                          </div>
                          <p className="text-uppercase them-nav-link-text">Discussion</p>
                        </Link>
                      </div>
                      <div className="theme-dropdown-box">
                        <Link
                          to={{ pathname: 'https://twitter.com/gneeksapp' }}
                          target="_blank"
                          className="d-flex align-items-center theme-dropdown-link"
                        >
                          <div>
                            <img
                              style={{ width: '40px', display: 'inline-block' }}
                              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-twitter-emoji.png"
                              alt=""
                            />{' '}
                          </div>
                          <p className="text-uppercase them-nav-link-text">Public Opinion</p>
                        </Link>
                      </div>
                      <div className="theme-dropdown-box">
                        <Link
                          to={{ pathname: 'https://www.instagram.com/godsandgeeks/' }}
                          target="_blank"
                          className="d-flex align-items-center theme-dropdown-link"
                        >
                          <div>
                            <img
                              style={{ width: '40px', display: 'inline-block' }}
                              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-instagram-proof.png"
                              alt=""
                            />{' '}
                          </div>
                          <p className="text-uppercase them-nav-link-text">Social Proof</p>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="theme-nav-right-side d-flex align-items-center">
            <ul className="theme-nav-list d-flex align-items-center gap-2">
              <li className="theme-nav-right-list-item">
                <Link to="/team" className="text-white text-uppercase andale">
                  Members
                </Link>
              </li>
              <li className="theme-nav-right-list-item">
                <Link to="/roadmap" className="text-white text-uppercase andale">
                  Roadmap
                </Link>
              </li>

              <li className="theme-nav-right-list-item">
                <Link to="/litepaper" className="text-white text-uppercase andale">
                  Litepaper
                </Link>
              </li>

              <li className="theme-nav-right-list-item">
                <NewConnect />
              </li>
            </ul>
          </div>
          {/* <button className="connect-wallect-btn">Connect</button> */}

          <div className="theme-nav-toggle" onClick={() => setOpen(true)}>
            <FontAwesomeIcon icon={faBars} className="toggle-icon" />
          </div>
        </div>
        {isDoc ? null : (
          <div className="theme-sub-nav">
            <div
              className="d-flex"
              style={{
                maxWidth: '150px',
                position: 'absolute',
                top: '-25px',
                right: '20px',
              }}
            >
              <a href="https://discord.gg/XXdEwbJ82z" target="_blank" rel="noreferrer noopener">
                <div className="discrod-icon" />
              </a>
              <a href="https://twitter.com/gneeksapp" target="_blank" rel="noreferrer noopener">
                <div className="twitter-icon" />
              </a>
              <a
                href="https://www.instagram.com/godsandgeeks/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="instagram-icon" />
              </a>
            </div>
          </div>
        )}
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" style={{ fontSize: '40px', color: '#000' }}>
                  &times;
                </span>
              </button>
            </div>
            <div
              className="modal-body text-white"
              style={{ textAlign: 'center', margin: '0px auto' }}
            >
              <form
                id="fs-frm"
                name="department-contact-form"
                acceptCharset="utf-8"
                action="https://formspree.io/f/xknkljbq"
                method="POST"
                className="modal-form-wait"
              >
                <h3
                  className="fw-bold"
                  id="exampleModalLabel"
                  style={{
                    textAlign: 'center',
                    margin: '0px auto',
                    color: '#05001f !important',
                  }}
                >
                  JOIN WAITING LIST
                </h3>

                <p style={{ color: '#05001f' }}>
                  The games will soon begin. <br />
                  Be first to know about the drop.
                </p>

                <input
                  type="email"
                  name="_replyto"
                  className="form-control"
                  id="inputEmail3"
                  placeholder="Email"
                  required
                />

                <select
                  className="custom-select custom-select-lg mb-3"
                  name="_interest"
                  id="interest"
                  required=""
                >
                  <option selected>Choose Interest</option>
                  <option value="ISSUE_#1_NFT_DROP">NFT Comic Whitelist</option>
                  <option value="EarthXClass_Donor">EarthXClass Token (Philanthropist)</option>
                  <option value="EarthXClass_Investor">EarthXClass Token Presale (Investor)</option>
                  <option value="MarxXClass_Teacher">MarsXClass Token (Teacher)</option>
                  <option value="ProximaXClass_Student">ProximaXClass Token (Student)</option>
                  <option value="Academic_Research">Academic Research</option>

                  <option value="P2E">Play 2 Earn Game Access</option>
                  <option value="Integrations">$CLASSX Developer SDK</option>
                </select>

                <br />

                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  style={{
                    backgroundColor: '#05001f',
                    border: '1px solid #05001f',
                    width: '100%',
                    borderRadius: '20px !important',
                  }}
                >
                  <strong>JOIN WAITLIST</strong>
                </button>
              </form>
            </div>
            <div className="modal-footer" />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewNav;
