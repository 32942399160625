import './hero.css';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';

import { NewWaitList } from '../../../components';

const GamingHero = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div className="gaming_hero">
      <img
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-logo-new.png"
        className="logo p-0"
        alt=""
      />
      <p className="text-white text-center p-0 fw-regular description">
        The more you know, the more you&apos;re worth.
      </p>

      <div className="col-md-8 ui-layout-column-center">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/1.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/1.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/2.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/vero/1.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/3.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/4.png"
              className="logo p-0"
              alt=""
            />
          </div>

          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/4.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/vero/1.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/zion/0.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/12.png"
              className="logo p-0"
              alt=""
            />
          </div>
          <div className="">
            <img
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/gou/5.png"
              className="logo p-0"
              alt=""
            />
          </div>
        </OwlCarousel>
      </div>

      <p className="text-white text-center px-4 caption-this">
        Welcome to the first on-chain book club for computer science enthusiasts. Our mission is to
        improve adolescent literacy around the world. Buy our PFP and join an ecosystem that gives
        back to the hardest hit communities worldwide.
      </p>

      <div className="btn_container">
        <NewWaitList />
      </div>
    </div>
  );
};

export default GamingHero;
