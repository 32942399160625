import './legends.css';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

import { NewWaitList } from '..';

const Legends = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div>
      <section className="legends">
        <img
          src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/icon-electromagnetism.png"
          className="symbol-icon"
          alt=""
        />
        <h1 className="burbank display-5 text-white text-center">LEGENDS</h1>
        <p className="ui-text-intro text-white text-center">
          Discover the genius of our technology legends and earn their powers.
        </p>
        <br />

        <div className="container">
          <OwlCarousel className="owl-theme" {...options}>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/THOMPSON_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Unix</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/TURING_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Turing Complete</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/MUSK_0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Disrupt Everything</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/EINSTEIN-0006.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Time is an Illusion</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/HOPPER_0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Running Compiler</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/JOBS_0003.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Crazies</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/HUTCHINS_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">WannaCry</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/FARADAY-0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Weird Science</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/LEE_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">For Everyone</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/MOUTON_0003.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Chief of Research</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/ZUCKERBERG_0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Fake News</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/BABBAGE-0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Paper Prototyper</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/MCNAIR_0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Black Genius</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/GATES-0004.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Be Nice to Nerds</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/LINUS-0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">I am Your God</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/TYSON_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">The Cosmos</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/MAXWELL_0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">∇xH = J +∂D/∂t</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="">
              <div className="container p-2">
                <div className="content">
                  <div className="content-overlay" />
                  <Link to=".">
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/BANZI_0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <h3 className="content-title">Open Hardware</h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
        <br />
        <br />
        <NewWaitList />
      </section>
    </div>
  );
};

export default Legends;
