import 'markdown-navbar/dist/navbar.css';
import './whitepaper.css';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';

import { Nav } from '../../components';

const About = () => {
  const [show, setShow] = useState(false);

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <div style={{ height: '150px' }} className="paper-nav">
        <Nav path="whitepaper" isDoc />
      </div>
      <div className="whitepaper_container">
        <Row>
          <Col xs={12} lg={1} />
          <Col xs={12} lg={7}>
            <br /> <br />
            <div>
              <p>
                Home /
                <a href="/litepaper" className="text-blue">
                  Litepaper
                </a>
              </p>
              <p>Page last updated: March 26, 2022</p>
              <div className="content_sidebar_mobile">
                <div
                  aria-hidden="true"
                  className="content_sidebar_mobile_header"
                  onClick={() => setShow(!show)}
                >
                  <span>On this page</span>
                  <div role="button">
                    {show ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </div>
                </div>
                <div className={show ? 'show_content' : 'hide_content'}>
                  <ol>
                    <li className="text-dark">
                      <a href="#overview">Overview</a>
                      <ul>
                        <li className="text-dark">
                          <a href="#$blockchain">Why Blockchain?</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$broken">What is Broken?</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$howlong">How Long?</a>
                        </li>
                      </ul>
                    </li>
                    <li className="text-dark">
                      <a href="#assets">Collectable Assets</a>
                      <ul>
                        <li className="text-dark">
                          <a href="#$gneeks">Gneeks</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$bookgames">Book Games</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$thrones">Thrones</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$crystals">Crystals</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$legends">Legends</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$elements">AxiomX Elements</a>
                        </li>
                      </ul>
                    </li>
                    <li className="text-dark">
                      <a href="#token">$CLASSX Token</a>
                      <ul>
                        <li className="text-dark">
                          <a href="#$treasury">Treasury</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$governance">Governance</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$members">Members</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$advisors">Advisors</a>
                        </li>
                        <li className="text-dark">
                          <a href="#$proposals">Proposals</a>
                        </li>
                      </ul>
                    </li>
                  </ol>
                </div>
              </div>
              <h1 id="overview">Overview</h1>
              <p>
                Patents, research papers, curriculums, guidelines, academic datasets... what if
                these were all NFTs? The Gneeks.org is an on-chain academic ecosystem with one goal:
                decentralize education. We&apos;re one of the first communities asking questions
                about the intersection of blockchain and education. As such, we&apos;ve embodied our
                proof of concepts in new projects built by our members. Serving as an incubator, a
                treasury and innovation lab, the Gneeks faction will be home to some of the smartest
                academics, designers, engineers and educators on the planet.
              </p>
              <p>
                In order to disrupt the &apos;Powers that Be&apos;, you must exercise your board
                powers to determine how to steer the treasury budget towards derrivative products
                that address the problems in global education. Examples of such products and problem
                spaces within education are addressed throughout this paper.
              </p>
              <p>
                By minting on June 18th, and joining the Gneek faction, you&apos;ll secure your spot
                in the revolution. With each set of asset classes, you&apos;ll acquire the tools,
                resources and funding you&apos;ll need to change the World. While Gneeks and $CLASSX
                plays like a strategy game, the real-world impact is serious.
              </p>
              <p>
                <b>First generation of on-chain asset classes include:</b>
              </p>
              <ul>
                <li>
                  <b>Gods & Geeks:</b> an inclusive 1st generation PFP collection giving 10,000
                  members a voice, with a set of options for moving ahead and taking action. Spots
                  are intended for education system problem solvers, innovators and critical
                  thinkers.
                </li>
                <br />
                <li>
                  <b>Book Games:</b> four interactive NFT and print books that guide the ecosystem
                  through the origins of technology and computer science culture. Owning your
                  character, obtaining scannable items, airdrops, and resources become your
                  utilities for making change. Book Games will keep you on track and serve as a
                  storymode guide to the daunting challenges we face disrupting the global education
                  system.
                </li>
                <br />
                <li>
                  <b>Thrones & Crystals:</b> board and adivsory seats that give special privileges
                  to community members working towards the ultimate goal: decentralize.
                </li>
                <br />
                <li>
                  <b>$CLASSX Token:</b> a governance token airdropped to gen 1 PFP NFT and book game
                  holders. Use tokens to purchase thrones and crystals for $CLASSX board of
                  education voting power.
                </li>
              </ul>
              <br />
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/workflow-classx-foundations-diagram_144dpi.jpg"
              />
              <p>
                <a
                  href="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/workflow-classx-foundations-diagram.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  View High Resolution PDF
                </a>
              </p>
              <br />
              <p>
                The second set of student asset classes will support the end users of the education
                products (teachers and students) built by the Gneek ecosystem. Current proposals
                include:
              </p>
              <p>
                <ul>
                  <li>
                    <b>AxiomX:</b> a community-managed computer science syllabus and curriculum that
                    resembles a periodic table.
                  </li>
                  <br />
                  <li>
                    <b>The Hacker Way:</b> computer science, cryptography and cyber security
                    awareness choose your own adventure game for desktop with play-to-earn utilities
                    such as: gamer tokens, symbols, AxiomX elements and legends.
                  </li>
                  <br />
                  <li>
                    <b>Node Chronicles:</b> digital text technology literacy app for iPhone and
                    Android. Unlock achievements (student asset classes) for reading about
                    technology history and computer science.
                  </li>
                  <br />
                  <li>
                    <b>Symbols:</b> earnable achievements representing critical facets of
                    technology.
                  </li>
                  <br />
                  <li>
                    <b>Legends:</b> earnable all-time technology legends awarded for completing
                    technology history lessons.
                  </li>
                  <br />
                  <li>
                    <b>Trunks:</b> update your Gen 2 PFP&apos;s metadata with your unlocked student
                    asset classes using a trunk.
                  </li>
                </ul>
              </p>
              <p>
                <b>Note:</b> baseline content, prototypes, alpha products and artwork for each of
                the proposals mentioned above have already been produced in one form or another.
                Gneeks.org is currently in the stage of community and smart contract development to
                bring said assets and products on-chain. Explore deeplinks throughout the
                <a href="/">website</a> for examples of progress and methodology within each of
                these areas.
              </p>
              <p>
                <b>Let the Games Begin:</b> From here on out, learn everything you can about the
                Gneek Faction, Technology and the power of DAPPs.
              </p>
              <h2 id="$blockchain">Why Blockchain?</h2>
              <p>
                Blockchain gives us a way to materialy connect people in pursuit of a common goal.
                This paper examines the methods in which a guild of academic experts can work
                together in a distributed education ecosystem, while being compensated for their
                intellectual contribution, influence and/or participation.
              </p>
              <p>
                In simple terms, people, technology, content and funding are necessary to develop
                new systems. Community educators and students must also be a part of this equation.
                NFTs, smart contracts and tokens are on-chain tools built to distribute value and
                support, wherever needed.
              </p>
              <h2 id="$blockchain">Why Decentralize?</h2>
              <p>
                For centuries, employees, writers, developers and adivsors relied on market-rate
                compensation with upside in the form of: potential notoriety, paper contracts, stock
                options, or perhaps even a good old fashioned handshake in exchange for their time
                and effort. The reason decentralized apps and DAO&apos;s are so appealing is because
                they toss this old way of working out the window. Smart contracts and tools like
                Etherium give constituents the motivation to work within an ecosystem with real-time
                reward and proportional ownership. As workers within the hive come together under
                smart contract and token governance, they become true owners, incentivized with
                digitial equity, instant royalties, liquidity and the ability to control and upward
                manage the organization. Ultimately, web3 platforms such as Gneeks and $CLASSX offer
                more favorable working conditions, say-so and reward, not only for those who create
                within the system, but for those who promote, teach, and use the system.
              </p>
              <p>
                Before we get into things like tokenomics and the treasury, let&apos;s be sure we
                all understand what exactly is taking place at Gneeks.org. The asset classes we
                introduce in this litepaper are programmed with utilities that unlock the
                possibility of best practice consensus, project funding, and blockchain ledgers to
                house many facets of verification, data and information. Beginning with our first
                generation PFP, the Gods & Geeks Genesis NFTs, holders are given the option to
                perform a variety of subsequent actions.
              </p>
              <p>
                <b>Such actions include:</b>
              </p>
              <ul>
                <li>Obtain a position on the board of directors (thrones & crystals).</li>
                <li>Develop and vote on proposals (governance).</li>
                <li>Speculate, arbitrage, and help finance the mission (hodl, fud, flip, fomo).</li>
                <li>Control the direction of treasury spending.</li>
                <li>Earn while learning and contributing.</li>
                <li>Steer critical values represented in the end products.</li>
                <li>
                  Collaborate in a worker ready ecosystem that supports open ideation, best practice
                  design, product development, and distribution.
                </li>
                <li>
                  Produce on-chain, verifiable instructional frameworks, lesson plans, guidelines,
                  and curriculums.
                </li>
                <li>
                  Analyze non-personally identifiable product usage data for independent review.
                </li>
              </ul>
              <p>
                In short, academic institutions could never be successful without tuition,
                endowments and research grants. No text book company could ever make a difference
                without paid writers, revenues and a supply chain. And a valuable source of
                knowledge such as Wikipedia wouldn&apos;t exist without their foundation and
                community of thousands of contributors. The difference between Gneeks.org and these
                other impact models rests in one simple fact: reverse ownership. Gneeks.org
                governance is led by you, the contributor, the user, the thought leader. For
                everyone, forever.
              </p>
              <p>
                Lastly, Gneeks.org takes on an embodiment approach in everything it delivers. The
                entire orchestration of the project is a game unto itself. Each moment you spend
                reading texts like these, enhancing your vocabulary, interacting with board members,
                is a moment invested in the next generation.
              </p>
              <h2 id="$broken">What is Broken?</h2>
              <p>
                Many experts would agree that it would be easier to colonize Mars than change our
                global education system. With supporting data illustrating huge disparity in how
                students perform across reading, math and science in various countries, there&apos;s
                an overwhelming set of challenges holding students back. Socioeconomic
                disadvantages, role models, cultural motivation, consensus, and access create an
                underwhelming experience in many communities. Furthermore, there are many teachers
                and students operating in poor conditions, without funding and access to critical
                methodologies and programming. In our research, we&apos;ve dug up a plethora of data
                to support these theories.
              </p>
              <p>
                <b>A decentralized education system holds the promise of:</b>
              </p>
              <ul>
                <li>Establishing curriculum consensus.</li>
                <li>A support ecosystem ready for advocacy and action.</li>
                <li>Educator income supplementation.</li>
                <li>Classroom funding supplementation.</li>
                <li>Incentivizing student participation.</li>
                <li>Channeling grants and donations to those in need.</li>
              </ul>
              <h2 id="$howlong">How Long?</h2>
              <p>
                Amazon was not the first eCommerce website. Without Bitcoin, Ethereum may have never
                been created. Without the Gneeks faction, the eventual goal of decentralizing
                education will only take longer. The vision of Gneeks.org is big, and it will take
                time for the full system to come to fruitiion. Possibly, 3-5 years. In that time, we
                will have explored many aspects of decentralizing education. Some aspects might
                scale, others may need to be scrapped and/or redesigned. For now, it&apos;s all
                about taking the first step:
                <i>find the right people passionate about the overall mission.</i> The more members
                who join the board and the more treasury dollars we collect, the faster we move!
              </p>
            </div>
            <div>
              <br /> <h1 id="assets">Collectable Assets</h1>
              <p>
                Collectable assets offer a role playing approach to achieving the daunting task of
                revolutionizing education. When we discuss decentralizing academia, we refer to 3
                pillars in need of disruption:
              </p>
              <ul>
                <li>consensus among experts on what to teach, how, and when.</li>
                <li>
                  Equitable funding and resources for product makers, teachers and students
                  everywhere.
                </li>
                <li>
                  Secure access to on-chain usage data for independent studies and efficacy review.
                </li>
              </ul>
              <p>
                The asset classes defined below each provide a protocol and theoretical means for
                achieving the incredible task of decentralizing, one step at a time. From the very
                onset of community led reseaerch and development, to the fruition of finished
                products, the smart contracts offered shall bind all necessary utility to the
                wallets of each participant.
              </p>
              <h2 id="$gneeks">The Gods & Geeks Genesis NFT Series</h2>
              <p>
                The Gods & Geeks NFT asset class is where it all begins. Obtaining your PFP avatar
                is a way to secure your spot in the Gneek ecosystem. To demonstrate our purpose
                first intentions, we&apos;ve decided to give the PFP genesis collection away for
                free. The motivation being to first find the right members passionate about the
                mission before collecting any money.
              </p>
              <h3 id="$whygods">Why Gods?</h3>
              <p>
                It&apos;s no secret that the mysterious gods of Egypt shared many philisophical
                ideas about our origins as a species. Across all cultures, the highest diety most
                often refers to a god who formed the stars and planets out of chaos, in some
                primordial dark waters. Ironically, in physics, we are taught that out of darkness,
                the big bang produced gasses, dust, gravity and clumps of matter which formed our
                stars and planets. Dozens of other dieties were assigned to represent all apects of
                life, culture, education, craftsmanship, justice and more. We chose the gods of
                Egypt as our guide because they are perhaps one of the oldest, most complete
                curriculums we find in our history books.
              </p>
              <h3 id="$whygods">Why Geeks?</h3>
              <p>
                Behind every great technological achievement, somewhere up late, burning the
                midnight oil, reading, researching, expirimenting, there is a so called geek,
                working, sacrificing, yearning to make a difference, while the rest of the world
                sleeps on their opportunity. Geeks are one of our greatest achievements as a
                species. They are an evolution of the human race. They work on the worlds most
                intense problems using their tremendous brainpower. The Gods and Geeks collection is
                a celebration of the hard work, tenacity and dedication.
              </p>
              <h3 id="$whygods">What are Gneeks?</h3>
              <p>
                Gneeks, pronounced (ga-neeks), are geeks who have become gods / gurus in their
                respective field. In book one, Into the Ether, they are described as a new species
                of highly intelligent people tasked with overturning the &apos;Powers that Be&apos;
                and guiding humanity to the stars.
              </p>
              <h2 id="$bookgames">Book Games</h2>
              <p>
                Book Games are the provenance of Gneeks.org. Originally, co-creators Franck Jones
                and Christian Colbert set out on a mission to teach computer science and technology
                using comic books and digital texts. As they observed learners of all ages interact
                with their books, they found themselves lost in a sea of ideas on how to make
                reading more fun for adolescents and adults. They became obcessed with technology
                history, new education paradigms and the cultural barriers people face when joining
                the industry.
              </p>
              <p>
                Node Chronicles, The Hacker Way, was the earliest rendition of their book. Noted as
                the first graphic novel to interact with an iPhone and Android via image
                recognition, the books companion apps helped extend the comics with animated
                content, existential questions, fortunes, character unlocks and more. Soon after,
                the idea of transmedia became a thing as their products began engaging users on
                various levels, across multiple senses.
              </p>
              <p>
                From an operational point of view, Gneeks.org has established critical capabilites
                as an organization, including the ability to self publish printed books and
                merchandise, self distribute, conduct eCommerce operations and manage customer
                support. In 2021, Node Chronicles fulfilled several thousand print books via Shopify
                and Amazon.
              </p>
              <h3 id="$competitors">Competitors</h3>
              <p>
                When Punks Comic and Huxley dropped as NFT comics, Jones and Colbert decided to
                embrace blockchain and it&apos;s unique abilities to protect collectors, airdrop
                extra goodies and extend the lifetime value of their saga. Today, book one, Into the
                Ether, has become the center of inspiration for everything our community works for:
                culture, purpose, equality and prosperity. The values of our organization, what it
                means to be a contributing human, and a geek, and where we&apos;re all going, can be
                found in this intentional work of literature and art.
              </p>
              <p>
                Together, Punks Comic and the Huxley Saga represent many learnings that we are
                inspired by. Punks initial distribution model and Huxley&apos;s game mechanics have
                demonstrated that there is an abundance of room for creative innovation and
                opportunity within the NFT comic book space.
              </p>
              <p>
                Another stepping stone for future NFT projects, Gary Vee&apos;s book games have
                established an ecosystem of 350,000 members. Gneeks.org questions whether building a
                similar sized community of passionate academics is possible, with an altruistic end
                game, is something the NFT market wants.
              </p>
              <h2 id="$thrones">Thrones</h2>
              <p>
                Gneeks.org has established a board of education, whereby seat holding members are
                granted special abilities, such as the ability to create proposals, vote on spending
                bills and build quorum. Seats, otherwise known as Thrones, are NFT&apos;s that
                provide transformative utilities. By combining a throne with your PFP, a mutation to
                a god is peformed, and your original PFP may be burned, forever (deflationary). You
                may also consider a split, whereby your PFP shards into 6 new random PFPs, of which
                you may distribute or build upon (inflationary).
              </p>
              <ul>
                <li>Nun - Primeval Waters, Founder</li>
                <li>Re - Life on Earth, Chair</li>
                <li>Atum - Substance, Creator</li>
                <li>Amun - Abstract Notions, Culture</li>
                <li>Ptah - Craftsmanship, Builder</li>
                <li>Thoth - Learning and Writing, Educator</li>
                <li>Anubuis - Gatekeeper, Security</li>
              </ul>
              <br />
              <br />
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/paper-thrones.jpg"
              />
              <h2 id="$crystals">Crystals</h2>
              <p>
                Featured at a smaller entrance cost, Crystals are granted for smaller achievements,
                while extending the core values of the Gneeks.org ecosystem with 8 additional
                guiding principles. They including additional voting power. As an example, a culture
                throne with many crystals, may estabish the equivalent voting power of a Nun founder
                throne.
              </p>
              <p>
                Crystals are named after 8 additional Egyptian Gods, representing the following
                values:
              </p>
              <ul>
                <li>Mut - Family</li>
                <li>Maat - Justice & Equality</li>
                <li>Hathor - Love</li>
                <li>Sekhmet - Health</li>
                <li>Bastet - Home</li>
                <li>Shu - Environment</li>
                <li>Tefnut - Elements</li>
                <li>Khansu - Nature</li>
              </ul>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/paper-crystals.jpg"
              />
              <h2 id="$legends">Legends</h2>
              <p>
                Legends represent the historical context and evolution of technology. The ClassX
                writing team has studied the likeness of 100+ legends and developed short stories,
                comics, and illustrations of their greatest moments. A linear timeline representing
                each legends place in our history, and their greatest achievements, may be used by
                future implementations.
              </p>
              <p>
                Node Chronicles, The Hacker Way, was the first comic book ever to demonstrate
                machine vision with a companion iPhone and Android app.
              </p>
              <div className="player-wrapper">
                <ReactPlayer
                  url="https://player.vimeo.com/video/533026764?h=546f84e8df"
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
              <br />
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/paper-legends.jpg"
              />
              <h2 id="$elements">AxiomX Elements</h2>
              <p>
                The first rendition of the Node Chronicles iPhone and Android app featured an
                ability to nest computer science elements inside digital texts, which could be
                unlocked and earned for reading accomplishments. A core database and GraphQL
                database was been designed featuring 10 Branches of Technology. Each element acts as
                a storage container for the defintion, metadata, links to resources, and threaded
                contextual conversations. In the future, AxiomX elements might become NFTs granted
                to those who study them and work within their scope.
              </p>
              <ul>
                <li>Math</li>
                <li>Physics</li>
                <li>Design</li>
                <li>Hardware</li>
                <li>Code</li>
                <li>Protocols</li>
                <li>Datatypes</li>
                <li>Algorithm</li>
                <li>Sensors</li>
                <li>Tooling</li>
              </ul>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/paper-axiomx.jpg"
              />
              <br />
              <img
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/paper-elements.png"
                alt=""
              />
            </div>
            <div>
              <br /> <h1 id="token">$CLASSX Token</h1>
              <p>
                <b>Note:</b> This area of the paper is under development.
              </p>
              <p>
                Gneeks.org will create a governance token named $CLASSX and issue aidrops to PFP
                holders for holding. Book game owners will receive an even greater percentage of
                $CLASSX token airdrops. Use your tokens to purchase a throne or crystal.
              </p>
              <h2 id="$treasury">Treasury</h2>
              <p>
                A substantial portion of all NFT sales and creator fees will go to the treasury.
                Money in the treasury will be spent on education technology development, creative
                works and verifiable acts of education using Gneeks.org community developed systems.
              </p>
              <h2 id="$governance">Governance</h2>
              <p>
                Preferred governance will be managed by Throne and Crystal holders. Additional
                public voting power will be controlled by the $CLASSX token. A complete paper on the
                $CLASSX governance token is being developed.
              </p>
              <h2 id="$members">Members</h2>
              <p>
                The entire Gneeks.org team, ecosystem, platform and all of its assets will be
                on-chain. Effectively, the utilities of the Gneeks smart contracts will create a
                fully functioning decentralized board of education, capable of making critical
                decisions on behalf of the ecosystem.
              </p>
              <h2 id="$advisors">Advisors</h2>
              <p>
                Less involved members who wish to share their direction, ideas and concepts for
                proposals may join the board of advisors by obtaining crystals.
              </p>
              <h2 id="$proposals">Proposals</h2>
              <p>
                A web3 application is being developed to allow for proposal generation, quorum,
                voting and the allocation of treasury spending.
              </p>
              <br />
              <br />
              <br />
              <br />
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="navigation">
              <ol>
                <li className="text-dark">
                  <a href="#overview">Overview</a>
                  <ul>
                    <li className="text-dark">
                      <a href="#$blockchain">Why Blockchain?</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$broken">What is Broken?</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$howlong">How Long?</a>
                    </li>
                  </ul>
                </li>
                <li className="text-dark">
                  <a href="#assets">Collectable Assets</a>
                  <ul>
                    <li className="text-dark">
                      <a href="#$gneeks">Gneeks</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$bookgames">Book Games</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$thrones">Thrones</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$crystals">Crystals</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$legends">Legends</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$elements">AxiomX Elements</a>
                    </li>
                  </ul>
                </li>
                <li className="text-dark">
                  <a href="#token">$CLASSX Token</a>
                  <ul>
                    <li className="text-dark">
                      <a href="#$treasury">Treasury</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$governance">Governance</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$members">Members</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$advisors">Advisors</a>
                    </li>
                    <li className="text-dark">
                      <a href="#$proposals">Proposals</a>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default About;
