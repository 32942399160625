import './onPhone.css';

import React from 'react';
import ReactPlayer from 'react-player';

const OnPhone = () => {
  return (
    <div className="on_phone">
      <div className="col-md-8 ui-layout-column-center">
        <h1 className="burbank text-center text-white on_phone_title">ALPHA SNEAK PEAK</h1>

        <div className="">
          <br />
          <div className="player-wrappe">
            <ReactPlayer
              url="https://player.vimeo.com/video/529799824?h=248711c28a"
              className="react-playe"
              style={{ width: '100%', margin: '0 auto' }}
              width="450px"
              height="801px"
              controls
            />
          </div>
        </div>
        <h3 className="text-center andale text-white on_phone_small_text">
          Download the original Node Chronicles App and checkout Episodes 1 or The Hacker Way
          completely FREE!
        </h3>

        <div className="app_stores_links">
          <div>
            <a
              href="https://apps.apple.com/us/app/node-chronicles/id1552463165"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white.png"
                width="100%"
              />
            </a>
          </div>
          <div>
            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white-android.png"
              width="100%"
            />
          </div>
        </div>

        <h3 className="text-center andale text-white on_phone_small_text_green">
          We&apos;re working on a way better version 2 with play to earn features! All app verison 1
          app members will be notified first.
        </h3>
      </div>
    </div>
  );
};

export default OnPhone;
