import './footer.css';

import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <div>
      <footer role="contentinf" className="ui-section-footer">
        <Container>
          <div className="">
            <div className="footer__layout">
              <div>
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/footer-logos.png"
                />

                <p className="ui-text-note">
                  <br />
                  <small>A $CLASSX PROJECT 2022 &copy;</small>
                  <br />
                </p>
              </div>
              <div style={{ display: 'flex', gap: 10 }} />
            </div>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
