import React from 'react';

import { Hero } from '../../containers/Gaming';

const Characters = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};

export default Characters;
