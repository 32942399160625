import './faqs.css';

import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SingleQuestion from './Question';

const FAQs = () => {
  const questions = [
    {
      id: 1,
      title: 'What are Gneeks?',
      info: "Gneeks are a new species of academics that have risen from the ashes of confusion, corruption and fake news. Their mission is to decentralize education by any means necessary. Gneek PFP NFT's give you access to the on-chain academic ecosystem known as ClassX.",
    },
    {
      id: 2,
      title: 'Is there a limit on how many I can own?',
      info: 'You may only mint 1 free PFP, but you can purchase and accumulate others on the secondary market ie OpenSea.',
    },
    {
      id: 3,
      title: 'How do I get a Gneek?',
      info: 'There will be a limited supply of free mints. To mint you must have an Ethereum wallet such as MetaMask, with enough Ethereum to cover the gass fees for minting.',
    },

    {
      id: 4,
      title: 'What can I do with my PFP?',
      info: "With a PFP, you will be automatically whitelisted for the Gneeks comic book games, plus you'll be able to access our closed discord, and acquire thrones and crystals, which give your PFP special traits, powers and voting rights.",
    },
    {
      id: 5,
      title: 'When and where can I mint my PFP?',
      info: 'Minting will happen directly on Gneeks.org or through our contract address (advanced buyers). We begin free minting our genesis PFP on June 18th, 2022. Then, on June 25th, there will be a pre-mint for the whitelisters. Finally, on July 2nd, we will have our public mint. Visit our roadmap or see the mint details above for more information and to add the dates to your calendar.',
    },
    {
      id: 6,
      title: 'Is there a limit on how many PFPs I can own?',
      info: 'You may only mint 1 free PFP, but you can purchase and accumulate others on the pre-sale or public mint, or on the secondary market ie OpenSea.',
    },
    {
      id: 7,
      title: 'What are Thrones and how do I get one?',
      info: "Thrones are governance utility NFT's that add super powers and privellages to your PFP, such as voting rights, metadata updates and mutation abilities (transform into a god). Thrones are effectively decentralized board of education seats. When added to your PFP, your PFP will have the option to either mutate into a god or split into multiple PFP's so you can establish your own faction. Other utilities will be announced closer to mint time. You can begin working to earn a Throne on Discord today, or invest in them later.",
    },
    {
      id: 8,
      title: 'Can I work for a Throne or Crystal?',
      info: 'Yes, join our Discord server and ask a mod for direction. There is a role for everyone, including: moderators, educators, engineers, designers, artists, writers, shillers and marketing professionals.',
    },
    {
      id: 9,
      title: 'What are Crystals and how can I earn them?',
      info: "Similar to Thrones, crystals governance utility NFT's that add super powers and privellages to your PFP, such as voting rights, metadata updates and transformation abilities. The official utilities for Crystals will be announced closer to mint time, but they are essentially decentralized advisory board seats. You can begin working to earn Crystals on Discord today.",
    },
    {
      id: 10,
      title: 'Where can I learn more?',
      info: 'Read our litepaper and hop on our Discord server for more information. We will post some videos soon on how to mint, but it should be fairly easy if you have an Ethereum wallet with enough ETH to cover the mint costs and gass fees.',
    },
    {
      id: 11,
      title: "What's up with the free mint?",
      info: 'Early ecosystem members particpating on Discord who have reserved their spot on gneeks.org will be eligble for a free mint spot. We will increase available free mint spots on a discrtionary basis as more members join our community and bring their A game.',
    },
    {
      id: 11,
      title: "What's the diff between free-mint and pre-mint?",
      info: "The free mint is it's own whitelist, and of course, the mint price is free (pay gas only). The pre-mint whitelist is for early ecosystem members who wish to buy a 2nd or 3rd PFP and execute their trading strategy. The Free Mint and Pre-Mint will be held on seperate dates (1 week apart) on June 18th and June 25th.",
    },
  ];
  return (
    <div className="faqs-section">
      <br />
      <h1 className="text-white text-center text-uppercase burbank">Frequent Questions</h1>
      <div className="faqs-container">
        <Row>
          {questions.map((question) => (
            <Col key={question.id} sm={12} md={6} lg={6}>
              <SingleQuestion {...question} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default FAQs;
