import './hero.css';

import React from 'react';
import ReactPlayer from 'react-player';

import { NewWaitList } from '../../../components';

const Legends = () => {
  return (
    <div className="hacker-way-hero">
      <div className="col-md-12 ui-layout-column-center">
        <p className="gneeks-subtitle video-subtitle-shim">
          Gou Sokyeo discovers the legend of The Hacker Way
        </p>
        <br />
        <br />
        <div>
          <div className="player-wrapper">
            <ReactPlayer
              url="https://player.vimeo.com/video/679582863?h=416e740427?playsinline=0"
              className="react-player"
              width="100%"
              height="100%"
              controls
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
            />
          </div>
          <br />
          <br />
          <div className="col-md-10 ui-layout-column-center">
            <p className="text-white m-0 p-0 pb-3 text-center courier">
              <b>Fun Fact: </b>
              Gneeks.org and $CLASSX makes you smarter.
            </p>
            <br />
            <NewWaitList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legends;
