import React from 'react';

import { Footer, Nav, NotificationBar } from '../../components';
import { Starrin, VideoHero } from '../../containers/BookGames';
import { Preview, Samples } from '../../containers/Comics';
import { BuyArea } from '../../containers/Gneeks';
import { Moments } from '../../containers/LandingPage';

const Gneeks = () => {
  return (
    <div>
      <NotificationBar
        text={[
          '📗 An interactive book club for students of the game.',
          '🌎 Only you have the power to change the world. Book games gets you there.',
          '🔓 Unlock thrones, elements and legends with book games.',
        ]}
        bg="#cc23e4"
        color="#000"
      />
      <Nav />
      <VideoHero />
      <Preview />
      <Samples />
      <BuyArea />

      <Starrin />
      <Moments />
      <Footer />
    </div>
  );
};

export default Gneeks;
