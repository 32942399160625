import './team.css';

import React from 'react';

import { Footer, Nav } from '../../components';
import { Members } from '../../containers/Team';

const Team = () => {
  return (
    <div className="team-page">
      <Nav />
      <Members />
      <Footer />
    </div>
  );
};

export default Team;
