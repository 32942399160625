import React from 'react';

import { NewWaitList } from '../../../components';

const Legends = () => {
  return (
    <div className="gneeks-legends">
      <div className="col-md-8 ui-layout-column-center">
        <div>
          <p className="gneeks-subtitle subtitle-shim">also starring</p>
          <br />
          <h1 className="m-0 p-0 stounderSquaredBold text-white text-center gneeks-title">
            Zion Robinson
          </h1>
          <br />

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 ui-layout-column-center">
                <br />

                <img
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/zion-robinson-the-hacker-way.png"
                  alt=""
                  className="zion-image"
                />
                <br />
                <br />
                <NewWaitList />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legends;
