import './video.css';

import React from 'react';
import ReactPlayer from 'react-player';

const Video = () => {
  return (
    <div className="app_video">
      <div className="col-md-7 ui-layout-column-center">
        <div className="player-wrapper">
          <ReactPlayer
            url="https://player.vimeo.com/video/679582863?h=416e740427"
            className="react-player"
            width="100%"
            height="100%"
            controls
          />
        </div>
      </div>
    </div>
  );
};

export default Video;
