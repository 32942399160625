import React from 'react';

import { Footer, Nav, NotificationBar } from '../../components';
import { Hero, PlayIn } from '../../containers/Classx';

const Classx = () => {
  return (
    <div>
      <NotificationBar
        text={[
          'ACADEMICS: Help build the first decentralized education system.',
          'Publish on-chain content for for everyone, forever!',
          'Congregate with other education visionaries and take action.',
        ]}
        bg="#fff441"
        color="#000"
      />
      <Nav />
      <Hero />
      <PlayIn />
      <Footer />
    </div>
  );
};

export default Classx;
