import React, { useState } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";

import "./waitlist.css";

function WaitList({ title }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [state, handleSubmit] = useForm("xknkljbq");

  return (
    <div>
      <button
        type="button"
        className="connect-wallect-btn"
        onClick={handleShow}
      >
        RESERVE SPOT
      </button>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        classname="modal"
        centered
      >
        <div className="new-waitlist-modal">
          <h1 className="title burbank text-white text-center">Reserve spot</h1>
          <p className="andale text-white text-center">
            join alpha and grab a whitelist spot
          </p>
          <Row>
            <Col sm={12} md={5} lg={5}></Col>
            <Col sm={12} md={7} lg={7}>
              <form
                id="fs-frm"
                name="department-contact-form"
                accept-charset="utf-8"
                action="https://formspree.io/f/xknkljbq"
                method="POST"
                className="modal-form-waitlist"
              >
                <input
                  type="email"
                  name="_replyto"
                  className="input-field"
                  id="inputEmail3"
                  placeholder="Email Address *"
                  required
                />
                <br />
                <input
                  type="text"
                  name="twitter"
                  id="twitter"
                  className="input-field"
                  placeholder="Twitter Handle"
                  
                />
                <br />
                <textarea
                  placeholder="Eth Wallet Address *"
                  rows={5}
                  className="input-field"
                  name="wallet"
                  id="wallet"
                  required
                ></textarea>

                <br />
                <br />

                <button
                  className="pushable"
                  onClick={() => {
                    setShow(true);
                    handleSubmit();
                  }}
                >
                  <span className="front front_small _burbank">
                    Submit Info
                  </span>
                </button>
              </form>
              <p className="andale text-center text-white">
                * indicates required <br /> No spam. Data private.
              </p>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default WaitList;
