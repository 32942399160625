import './mintdetail.css';

import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import { AiFillFileText } from 'react-icons/ai';
import OwlCarousel from 'react-owl-carousel';

import { client } from '../../../apollo/client';
import { NewWaitList } from '../../../components';

const MintDetail = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  const options = {
    // margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const GET_GRANT = gql`
    query ($search: String) {
      grants(where: { wallet: { _eq: $search } }) {
        id
        email
        twitter
        discord_username
        wallet
        free_mint
        pre_mint
        throne_nun
        throne_re
        throne_atum
        throne_amun
        throne_ptah
        throne_thoth
        throne_anubis
        crystal_mut
        crystal_maat
        crystal_hathor
        crystal_sekhmet
        crystal_bastet
        crystal_shu
        crystal_tefnut
        crystal_khansu
      }
    }
  `;

  const [walletAdd, setWalletAdd] = useState('');
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const search = walletAdd;
  const getWallet = async () => {
    const result = await client.query({
      query: GET_GRANT,
      variables: { search },
    });
    // console.log('=============>', result.data.grants[0]);
    setData(result.data.grants[0] === undefined ? [] : result.data.grants[0]);
    setLoader(true);
  };

  return (
    <div className="mint-detail">
      <Container className="pb-0">
        <div className="mobile">
          <OwlCarousel className="owl-theme" {...options}>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/3_ghostrider_purple.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/1_flames.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/2_wutang_jason.jpg"
                className="rounded"
              />
            </div>

            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/4_electric_blue.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/5_tmnt.jpg"
                className="rounded"
              />
            </div>

            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/15_vero_parka.jpg"
                className="rounded"
              />
            </div>

            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/6_army_general.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/13_zion_lime.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/7_shadow_codex.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/8_geazy.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/9_street_baller.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/10_hell_boombox.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/11_biker_baller.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/12_white_rapper.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/14_zion_robinson.jpg"
                className="rounded"
              />
            </div>
            <div className="p-3">
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/17_vero_blue.jpg"
                className="rounded"
              />
            </div>
          </OwlCarousel>
        </div>

        <Row className="align-items-center m-0 p-0">
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className="mint-detail-content py-5 px-0">
              <h1 className="text-white burbank">Mint Details</h1>
              <p className="text-white m-0 p-0 pb-3">
                Free minting, or buying on the secondary market, and holding reserves your right to
                a gang of book games and token airdrops, exclusive print products, merchandise, plus
                access to our ALPHA #P2E challenge. Holders will also find glorious opportunities to
                earn or invest in Board Thrones and Crystals to influence further control of the
                gneek.org treasury spending. 45% of all PFP and 70% of all Throne and Crystal
                proceeds will be sent to the treasury vault. Read our{' '}
                <a href="/litepaper" className="text-yellow">
                  litepaper
                </a>{' '}
                for more info.
                <br />
                <br />
                <div className="mint-dates">
                  <p>
                    <b>Free-Mint:</b> June 18th -{' '}
                    <a
                      title="Add to Calendar"
                      className="text-yellow"
                      data-id="qd13860052"
                      href="https://www.addevent.com/event/qd13860052"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      Add to Calendar
                    </a>
                    <script
                      type="text/javascript"
                      src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                      async
                      defer
                    />
                  </p>
                  <br />
                  <p>
                    <b>Pre-Mint:</b> June 25th -{' '}
                    <a
                      title="Add to Calendar"
                      className="text-yellow"
                      data-id="Ui13860100"
                      href="https://www.addevent.com/event/Ui13860100"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      Add to Calendar
                    </a>
                    <script
                      type="text/javascript"
                      src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                      async
                      defer
                    />
                  </p>
                  <br />
                  <p>
                    <b>Public Sale:</b> July 2nd -{' '}
                    <a
                      title="Add to Calendar"
                      className="text-yellow"
                      data-id="yr13860109"
                      href="https://www.addevent.com/event/yr13860109"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      Add to Calendar
                    </a>
                    <script
                      type="text/javascript"
                      src="https://cdn.addevent.com/libs/atc/1.6.1/atc.min.js"
                      async
                      defer
                    />
                  </p>
                </div>
              </p>
              <br />
              <NewWaitList />
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6}>
            <div>
              <table className="mint-detail-table px-0">
                <tr>
                  <td style={{ width: '150px', padding: '30px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">FREE MINT</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">
                      1st 500 SPOTS (FILLED)
                    </p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow(true);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">Pre-Mint</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">
                      0.1 ETH (2000 WL SPOTS)
                    </p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow2(true);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">Public Mint</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">0.2 ETH (7500 SPOTS)</p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow3(true);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">Book 1</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">PDF & PRINT AIRDROP</p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow4(true);
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">Thrones</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">
                      0.5 to 5 ETH (INVEST OR EARN)
                    </p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow5(true);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">Crystals</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">
                      0.01 to 0.05 ETH (INVEST OR EARN)
                    </p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow6(true);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '150px', padding: '10px 20px' }}>
                    <h2 className="p-0 m-0 text-white  burbank text-uppercase">$CLASSX</h2>
                  </td>
                  <td style={{ flex: 1 }}>
                    <p className="p-0 m-0 text-white andale mint-captions">TOKEN AIRDROP</p>
                  </td>
                  <td style={{ padding: '20px' }}>
                    <AiFillFileText
                      className="p-0 m-0 text-white "
                      onClick={() => {
                        setShow7(true);
                      }}
                    />
                  </td>
                </tr>
              </table>
            </div>
          </Col>
        </Row>
        <div className="container">
          <div style={{ paddingTop: '100px', maxWidth: '500px', margin: '0 auto' }}>
            <h3 className="text-center text-white text-uppercase bur-bank p-0 m-0">
              Check your spot status
            </h3>
            <div className="py-4">
              <input
                type="text"
                placeholder="Enter your wallet address to check"
                className="spot-input"
                onChange={(e) => setWalletAdd(e.target.value)}
              />
              <button className="search-btn" onClick={() => getWallet()}>
                <span className=" _burbank">Check Status</span>
              </button>
            </div>
          </div>
          {data.length === 0 && loader === false ? null : (
            <div>
              {data.length === 0 && loader === true ? (
                <p className="text-white text-center andale"> Wallet Not Found</p>
              ) : (
                <div className="row py-3" style={{ maxWidth: '900px', margin: '0 auto' }}>
                  <p className="m-0 text-white andale text-uppercase col-12 col-md-3">
                    Free Mint Spots: {data.free_mint}
                  </p>
                  <p className="m-0 text-white andale text-uppercase col-12 col-md-3">
                    Pre-Mint Spots: {data.pre_mint}
                  </p>
                  <p className="m-0 text-white andale text-uppercase col-12 col-md-3">
                    Throne Nun: {data.throne_nun === null ? 0 : data.throne_nun} <br />
                    Throne Re: {data.throne_re === null ? 0 : data.throne_re} <br />
                    Throne Atum: {data.throne_atum === null ? 0 : data.throne_atum} <br />
                    Throne Amun: {data.throne_amun === null ? 0 : data.throne_amun} <br />
                    Throne Ptah: {data.throne_ptah === null ? 0 : data.throne_ptah} <br />
                    Throne Thoth: {data.throne_thoth === null ? 0 : data.throne_thoth} <br />
                    Throne Anubis: {data.throne_anubis === null ? 0 : data.throne_anubis} <br />
                  </p>
                  <p className="m-0 text-white andale text-uppercase col-12 col-md-3">
                    Crystal Mut: {data.crystal_mut === null ? 0 : data.crystal_mut} <br />
                    Crystal Maat: {data.crystal_maat === null ? 0 : data.crystal_maat} <br />
                    Crystal Hathor: {data.crystal_hathor === null ? 0 : data.crystal_hathor} <br />
                    Crystal Sekhmet: {data.crystal_sekhmet === null ? 0 : data.crystal_sekhmet}{' '}
                    <br />
                    Crystal Bastet: {data.crystal_bastet === null ? 0 : data.crystal_bastet} <br />
                    Crystal Shu: {data.crystal_shu === null ? 0 : data.crystal_shu} <br />
                    Crystal Tefnut: {data.crystal_tefnut === null ? 0 : data.crystal_tefnut} <br />
                    Crystal Khansu: {data.crystal_khansu === null ? 0 : data.crystal_khansu} <br />
                  </p>
                </div>
              )}
            </div>
          )}
        </div>

        <p className="text-white text-center courier hero-cap">
          <b>Not So Fun Fact: </b>
          Less than half of US schools teach computer science (45%) and 65% of US students are
          behind in their reading levels, contributing to 8,000 students dropping out of high school
          every day.*
          <br />{' '}
          <div className="caption-small">
            Stats provided by: Code.org and the National Assessment of Educational Progress
          </div>
        </p>
        <div className="dekstop desktop-tiles" style={{ paddingBottom: '160px' }}>
          <Row className="justify-center">
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/3_ghostrider_purple.jpg"
                  className="rounded"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/8_geazy.jpg"
                  className="rounded"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/5_tmnt.jpg"
                  className="rounded"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/4_electric_blue.jpg"
                  className="rounded"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/14_zion_robinson.jpg"
                  className="rounded"
                />
              </div>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <div className="p-3">
                <img
                  alt=""
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/nft-sideviews/small/17_vero_blue.jpg"
                  className="rounded"
                />
              </div>
            </Col>
          </Row>
        </div>
        <img
          alt=""
          src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/proof-of-genius.png"
          className="proof-heading proof-image-shim-bottom"
        />
        <OwlCarousel className="owl-theme" {...options}>
          <img
            alt=""
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/proof-of-genius-gneeks-1.png"
            className="proof-image "
            style={{ height: '100%' }}
          />
          <img
            alt=""
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/proof-of-genius-gneeks-1.png"
            className="proof-image"
            style={{ height: '100%' }}
          />
          <img
            alt=""
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/proof-of-genius-gneeks-1.png"
            className="proof-image"
            style={{ height: '100%' }}
          />
        </OwlCarousel>

        <Modal
          show={show}
          // size="lg"
          onHide={() => setShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">FREE MINT 1</h1>
            <p className=" text-purple ">
              We&apos;re giving away 250 free PFP&apos;s spots to our first subscribers. Although
              the current spots are filled, we will considering opening up more free spots to early
              ecosystem members contributing on{' '}
              <a href="https://discord.gg/nHxb6B766t" target="_blank" rel="noreferrer">
                Discord.
              </a>
            </p>
            <p className=" text-purple font-bold m-0">
              <b>Wen Free Mint #1?</b>
            </p>
            <p className=" text-purple m-0">Free minting begins sometime this June</p>
          </div>
        </Modal>

        <Modal
          show={show1}
          // size="lg"
          onHide={() => setShow1(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">FREE MINT 2</h1>
            <p className=" text-purple ">
              We&apos;re also giving away 250 randomized free mint spots using a Chainlink and an
              oracle to decide who gets a spot. To reserve your spot, add your email and wallet
              address and you&apos;ll be notified if you won a free mint spot.
            </p>
            <p className=" text-purple font-bold m-0">
              <b>Wen Free Mint #2?</b>
            </p>
            <p className=" text-purple m-0">Free minting begins sometime in June</p>
          </div>
        </Modal>

        <Modal
          show={show2}
          // size="lg"
          onHide={() => setShow2(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">PRE-MINT</h1>
            <p className=" text-purple ">
              80% of whitelist members are established on a first-come-first-serve basis. However,
              20% of all available whitelist spots will be reserved and granted on a discretionary
              basis to helpers in our{' '}
              <a href="https://discord.gg/nHxb6B766t" target="_blank" rel="noreferrer">
                Discord
              </a>{' '}
              server. Gneek candidates exploring our library articles, demonstrating computer
              science projects or sharing technology news will have a greater chance of being
              recognized.
            </p>
            <p className=" text-purple font-bold m-0">
              <b>Wen Pre-Mint?</b>
            </p>
            <p className=" text-purple m-0">Pre-Minting begins some time this June.</p>
          </div>
        </Modal>

        <Modal
          show={show3}
          // size="lg"
          onHide={() => setShow3(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">Public Mint</h1>
            <p className=" text-purple ">
              The public mint will offer all remaining spots that were not free-minted or
              pre-minted. The public mint will happen within 48 hours of pre-mint.
            </p>
            <p className=" text-purple font-bold m-0">
              <b>Wen Public Drop?</b>
            </p>
            <p className=" text-purple m-0">Public Minting in June (offical date TBA)</p>
          </div>
        </Modal>
        <Modal
          show={show4}
          // size="lg"
          onHide={() => setShow4(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">Book Game #1</h1>
            <p className=" text-purple ">
              Book one, &bdquo;Into the Ether,&bdquo; (our first comic) will be airdropped as a FREE
              PDF and will be redeemable as a print for free for US based customers. International
              shipping will be charged seperately for print books and merchandise. Shipping prices
              will be calculated in our checkout.
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-book-variant-1.png"
                style={{ width: '320px' }}
              />
            </p>
            <p className=" text-purple font-bold m-0">
              <b>Wen Airdrop?</b>
            </p>
            <p className=" text-purple m-0">
              These books are finished and ready to go. You&apos;ll receive book one as soon as the
              genesis PFP collection is sold out!
            </p>
          </div>
        </Modal>

        <Modal
          show={show5}
          // size="lg"
          onHide={() => setShow5(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">Thrones (Board Seats)</h1>
            <p className=" text-purple ">
              Board Thrones give your PFP super powers, voting rights and the ability to transfrom
              into a god. You&apos;ll be able to work for a Throne, or invest in them like any other
              NFT. Ecosystem members involved in the broader mission and vision of Gneeks.org, and
              our goal to improve adolescent and adult literacy globally, will have greater chances
              of earning some of Thrones held back. You can learn more about them{' '}
              <a href="/board">here.</a>
            </p>
            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gem-atum-creator.png"
              style={{ width: '320px' }}
            />
            <p className=" text-purple font-bold m-0">
              <b>Wen Thrones?</b>
            </p>
            <p className=" text-purple m-0">
              Whitelisting and grants have already begun. Minting begins August/September, 2022.
            </p>
          </div>
        </Modal>
        <Modal
          show={show6}
          // size="lg"
          onHide={() => setShow6(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">CRYSTALS</h1>
            <p className=" text-purple ">
              Crystals give your PFP extra voting powers. You can earn them or invest in them.
              Obtain all crystals forms and receive a set of trait and metadata upgrades. You can
              learn more about them by scrolling to the bottom of <a href="/board">this page.</a>
            </p>

            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/crystal-mut.png"
              style={{ width: '320px' }}
            />
            <p className=" text-purple font-bold m-0">
              <b>Wen Giveaway</b>
            </p>
            <p className=" text-purple m-0">
              Whitelisting and grants begin August/September 2022. Minting begins sometime Q4, 2022.
            </p>
          </div>
        </Modal>
        <Modal
          show={show7}
          // size="lg"
          onHide={() => setShow7(false)}
          aria-labelledby="contained-modal-title-vcenter"
          classname="modal"
          centered
        >
          <div className="mint-modal p-3">
            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/class-x-school.png"
              style={{ width: '220px' }}
            />
            <h1 className="p-0 m-0 text-purple  _burbank text-uppercase">$CLASSX TOKEN AIRDROP</h1>
            <p className=" text-purple ">
              After all the Gneek gensis PFPS and sold out and the book games are airdropped, tokens
              will be airdropped to your wallet in proportion to the length of time you&apos;ve
              HODL&apos;d your PFP and book games. Orininal holders will be heavily incentivized
              with $CLASSX token. After all initial tokens are airdropped, additional tokens will be
              available for minting by the public, and a liquidity pool will be established on
              Uniswap and Sushiswap.
            </p>

            <p className=" text-purple font-bold m-0">
              <b>Wen Token Airdrop?</b>
            </p>
            <p className=" text-purple m-0">Sometime late 2022 or early 2023 (estimate)</p>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default MintDetail;
