import React from 'react';

import { Footer, Nav, NotificationBar } from '../../components';
import { Linux, VideoHero } from '../../containers/BookGames';
import { BuyArea, Characters, FAQs, Hero, MintDetail, Roadmap } from '../../containers/Gneeks';

const Gneeks = () => {
  return (
    <div>
      <NotificationBar
        text={[
          '🤯 NEWS: Gneeks to become the first on-chain academic ecosystem!',
          '💰💰💰 Gneeks offers 500 genesis PFP passes as a free mint!',
          '🏛 NEWS: Adolescent literacy expert Professor Leigh Hall joins team!',
          ' 🏛 NEWS: Awesome Labs and Token.art founders join dev team!',
        ]}
        bg="#df1783"
        color="#fff"
      />
      <Nav />
      <Hero />
      <MintDetail />
      <Roadmap />
      <BuyArea />
      <VideoHero />
      <Linux />
      <Characters />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Gneeks;
