import React from 'react';

import { Footer, Nav } from '../../components';
import { Hero } from '../../containers/Proposals';

const Gneeks = () => {
  return (
    <div>
      <Nav />
      <Hero />
      <Footer />
    </div>
  );
};

export default Gneeks;
