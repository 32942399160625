import React from 'react';

const Thanks = () => {
  return (
    <div>
      <div className="ui-layout-container">
        <br />
        <a href="https://gneeks.org">
          <img
            src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/footer-logos.png"
            style={{ width: '500px' }}
            alt=""
          />
        </a>

        <div className="px-4 pt-5 text-center" id="waitlist">
          <h1 className="display-5 text-white burbank">LET&apos;S GO!!!</h1>
          <p className="ui-text-intro text-white">
            <b>Welcome to the gneeks.org on-chain academic ecosystem.</b>
            <br />
            <br />
            Right now registrations for our mint are happening every single minute on the website.
            It’s looking like we are going to sell out quick... Join our discord community and find
            out how you can help support the mission and increase your chances at a free-mint spot,
            a WL spot or build value in your future PFP.
            <br />
            <br />
          </p>
          <div className="container">
            <div className="row justify-content-md-center">
              <a
                href="https://discord.gg/nHxb6B766t"
                target="_blank"
                aria-label="#"
                className=""
                rel="noreferrer"
              >
                <img
                  src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/big-discord-button.png"
                  width="80%"
                  alt=""
                />
              </a>
            </div>
            <div className="row justify-content-md-center">
              <img
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-crew2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thanks;
