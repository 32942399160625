import './board.css';

import React from 'react';

import { Footer, Nav } from '../../components';
import { Hero } from '../../containers/Board';

const Gneeks = () => {
  return (
    <div className="board-page">
      <Nav />
      <Hero />
      <Footer />
    </div>
  );
};

export default Gneeks;
