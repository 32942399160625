import './roadmap.css';

import React from 'react';

import { Popper } from '../../../components';

const Hero = () => {
  return (
    <div className="gneeks-roadmap">
      <img
        alt=""
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/cloud-1.png"
        className="cloud1"
      />
      <img
        alt=""
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/cloud-2.png"
        className="cloud2"
      />
      <img
        alt=""
        src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/cloud-3.png"
        className="cloud3"
      />
      <div className="col-md-8 ui-layout-column-center">
        <div>
          <p className="gneeks-subtitle peep-text">peep the</p>
          <br />
          <h1 className="m-0 p-0 stounderSquaredBold text-white text-center gneeks-title">
            roadmap
          </h1>
          <br />
          <div className="col-md-6 ui-layout-column-center">
            <p className="gneeks-subtitle text-uppercase date-text">
              <Popper
                title="Gneeks free mint"
                define="A limited number of genesis PFPs will be offered as a free mint."
                des="Inspired by Gou Sokyeo and frens, the Gneeks represent a new species of Gods and Geeks within the gneeks.org on-chain academic ecosystem."
              >
                <span className="uline">Gneeks free mint</span>
              </Popper>
              (June)
              <br />
              <Popper
                title="Book Games"
                define="Offered as an NFT and print edition, Book Games feature 4 books inspired by blockchain, computer science and electrical engineering."
                des="Book games feature a series of utility airdrops and redeemable objects such as thrones, crystals, elements and legends. Use such items to mutate your character and update their metadata. With higher privellages, do things like create proposals, vote and participate in verifiable acts of education."
              >
                <span className="uline">Book Games</span>
              </Popper>
              (July)
              <br />
              <Popper
                title="Board Minting"
                define="Obtain a throne or cyrstal and use it to mutate your character into a god."
                des="With god mode privellages take control of the $CLASSX board and participate in its DAO and web3 proposal process. Help decide how $CLASSX spends its treasury dollars on future DAPPS and on-chain education tools, for everyone, forever."
              >
                <span className="uline">Board Minting</span>
              </Popper>
              (August)
              <br />
              <Popper
                title="$CLASSX Token"
                define="The public governance token."
                des="The vision, as outlined in the paper, opens up more governance to the general public. The token also serves as an incentive utility for dispersing dividends and liquidity."
              >
                <span className="uline">$CLASSX Airdrop</span>
              </Popper>
              (TBD)
              <br />
              <br />
              <span className="">
                <a href="/roadmap" className="roadmap-full">
                  View Full Roadmap
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="crew-gradient">
        <img
          alt=""
          src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/gneeks-crew2.png"
        />
      </div>
    </div>
  );
};

export default Hero;
