import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';

import NewWaitList from '../NewWaitList';

const SemiRares = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <div>
      <section className="characters-bg">
        <img
          src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/icon-wave.png"
          className="symbol-icon"
          alt=""
        />
        <h1 className="burbank display-5 text-white text-center">POWER FIGURES</h1>
        <p className="ui-text-intro text-white text-center">
          Unlock and collect the profiles of these important power move makers.
        </p>
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 ui-layout-column-center">
              <OwlCarousel className="owl-theme" {...options}>
                <div className="post-slide">
                  <div className="content">
                    <div className="content-overlay" />
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/ERWIN-0001.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <Link to="characters/nikola-tesla" className="link-white">
                        <h3 className="content-title">False Reality</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="post-slide">
                  <div className="content">
                    <div className="content-overlay" />
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/TESLA-0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <Link to="/characters/nikola-tesla" className="link-white">
                        <h3 className="content-title">Pyramid Energy</h3>
                        <p>Checkout Demo</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="post-slide">
                  <div className="content">
                    <div className="content-overlay" />
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/LOVELACE-0002.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <Link to="/characters/Ken-Thompson" className="link-white">
                        <h3 className="content-title">Note G</h3>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="post-slide">
                  <div className="content">
                    <div className="content-overlay" />
                    <img
                      src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/nfts/MCNAIR_0006.jpg"
                      className="content-image nft-image"
                      alt=""
                    />
                    <div className="content-details fadeIn-bottom">
                      <Link to="/characters/james-prescott-joule" className="link-white">
                        <h3 className="content-title">Space G</h3>
                      </Link>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
              <br />
              <br />
              <NewWaitList />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SemiRares;
