/* eslint-disable */
// Global imports.
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/* eslint-enable */

import * as React from 'react';
// import { useEffect, useState } from 'react';
// import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

// import { client } from './apollo/client';
// import { auth } from './firebase';
// import PrivateRoute from './hooks/privateRoute';
import PublicRoute from './hooks/publicRoute.js';
import {
  Board,
  BookGames,
  Characters,
  Classx,
  Gneeks,
  Proposals,
  Roadmap,
  Team,
  Whitepaper,
  Thanks,
  AppScreen,

  // About,

  // CharactersPrivate,
  // Comics,
  // Dashboard,
  // Decentralized,
  // Discussion,
  // Elements,
  // ElementsDetail,
  // ElementsPrivate,
  // Forge,
  // Gaming,
  // Home,
  // HomeNew,
  // LandingPage,
  // Legends,
  // Mission,
  // Opinions,
  // Play,
  // Profile,
  // Projects,
  // Proof,
  // Reading,
  // Research,
  // Signin,
  // Signup,
  // SingleCharacter,
  // SmartContract,
  // Story,
  // StoryDetail,
  // StoryMode,
  // Test,

  // TheHackerWay,
  // Token,
  // Tokenomics,
  // Unlocks,
} from './pages';

const App = () => {
  const isAuthenticated = false;
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const unsubscribeFromAuthStateChanges = auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       setIsAuthenticated(true);
  //     } else {
  //       setIsAuthenticated(false);
  //     }
  //     setIsLoading(false);
  //   });

  //   return unsubscribeFromAuthStateChanges;
  // }, []);

  // if (isLoading) return <img src="/assets/bars.svg" alt="loader" className="loader-custom" />;
  return (
    // <ApolloProvider client={client}>
    <Router>
      <Switch>
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Gneeks}
          path="/"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={BookGames}
          path="/book-games"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Classx}
          path="/classx"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Board}
          path="/board"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Proposals}
          path="/proposals"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Team}
          path="/team"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Roadmap}
          path="/roadmap"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Whitepaper}
          path="/litepaper"
          exact
        />
        <PublicRoute
          authenticated={isAuthenticated}
          restricted={false}
          component={Characters}
          path="/characters"
          exact
        />

        <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Thanks}
              path="/thanks.html"
              exact
            />

            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={AppScreen}
              path="/app"
              exact
            />

        {/* <PrivateRoute
              authenticated={isAuthenticated}
              component={CharactersPrivate}
              path="/character"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={ElementsPrivate}
              path="/Element"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Legends}
              path="/legends"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Profile}
              path="/profile"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Reading}
              path="/character/:name"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={StoryDetail}
              path="/story/:name"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={ElementsDetail}
              path="/element/:name"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Unlocks}
              path="/unlocks"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={StoryMode}
              path="/storymode"
              exact
            />
            <PrivateRoute authenticated={isAuthenticated} component={Play} path="/play" exact />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Home}
              path="/auth-home"
              exact
            />
            <PrivateRoute
              authenticated={isAuthenticated}
              component={Dashboard}
              path="/dashboard"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Test}
              path="/test"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={HomeNew}
              path="/home"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Projects}
              path="/projects"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Gaming}
              path="/gaming"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Forge}
              path="/forge"
              exact
            />

            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Discussion}
              path="/discussion"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Opinions}
              path="/opinions"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Proof}
              path="/proof"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={TheHackerWay}
              path="/legacy"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={SmartContract}
              path="/smart-contract"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={About}
              path="/about"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Comics}
              path="/comics"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Token}
              path="/token"
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={LandingPage}
              path="/landing-page"
              exact
            />

            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Decentralized}
              path="/decentralized"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={SingleCharacter}
              path="/characters/:name"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Elements}
              path="/elements"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Signup}
              path="/register"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Signin}
              path="/signin"
              exact
            />
            
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Story}
              path="/story"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Mission}
              path="/mission"
              exact
            />

            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Research}
              path="/research"
              exact
            />
            <PublicRoute
              authenticated={isAuthenticated}
              restricted={false}
              component={Tokenomics}
              path="/tokenomics"
              exact
            />

             */}
      </Switch>
    </Router>
    // </ApolloProvider>
  );
};

export default App;
