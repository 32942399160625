import './hero.css';

import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import ReactPlayer from 'react-player';

const Hero = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      700: {
        items: 3,
      },
      850: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
  return (
    <div className="app_hero">
      <div className="app_hero_container">
        <div className="col-md-10 ui-layout-column-center">
          <h1 className="burbank text-white text-center app_hero_title">
            Coming back for more <br />
            means coming back for more
          </h1>
          <h2 className="text-center trade-gothic app_heo_sub_heading text-white">
            P2E using our APP and Book Games and level up your PFP&apos;s traits and metadata.
          </h2>

          <OwlCarousel className="owl-theme" {...options}>
            <div>
              <ReactPlayer
                playing
                muted
                loop
                playsinline
                width="100%"
                height="100%"
                url="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/app-store-node-chronicles-F.mp4"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_1.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_2.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_3.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_4.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_5.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_6.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_7.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_8.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_9.jpg"
                width="100%"
              />
            </div>
            <div>
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/appstore-images/65_10.jpg"
                width="100%"
              />
            </div>
          </OwlCarousel>
          {/* <img alt="" src="https://dummyimage.com/1200x500/000/000000" width="100%" /> */}

          <h3 className="text-center andale app_hero_small_text">
            A mission every community eductor. family member and NFT investor should be talking
            about.
          </h3>
        </div>
        <div className="app_stores_links">
          <div>
            <a
              href="https://apps.apple.com/us/app/node-chronicles/id1552463165"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white.png"
                width="100%"
              />
            </a>
          </div>
          <div>
            <img
              alt=""
              src="https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/app-store-white-android.png"
              width="100%"
            />
          </div>
        </div>
      </div>
      <h3 className="text-center andale text-white on_phone_small_text_green">
        Checkout the original proof of concept before it&apos;s too late! A new version is coming
        soon.
      </h3>
    </div>
  );
};

export default Hero;
