import './MintDapp.css';

import { formatDuration } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ethers } from 'ethers';
// import { web3Modal } from './walletConnector';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';

import { gneeksGouAbi } from './applicationBinaryInterface';
import { useInterval } from './lib/useInterval.ts';
import Whitelist from './merkle.ts';
import { MintingDappStateTesting } from './MintingDappStateTesting';

const DO_MANUAL_TESTING = false;
const CONTRACT_ADDRESS = '0x6c09dDC648f6aD5eB3DeeE6844cCe2B221165863';
const MINT_END_DATETIME = zonedTimeToUtc('2022-06-21 00:00:00.000', 'America/Chicago');
const ETH_NETWORK = 'mainnet';

let readwriteContract;
const p = new ethers.providers.AlchemyProvider(ETH_NETWORK, process.env.ALCHEMY_API_KEY);
const readonlyContract = new ethers.Contract(CONTRACT_ADDRESS, gneeksGouAbi, p);

// TODO: dedupe this function 👇.
// const getAddressAmountMinted = async (props) => {
//   const contractMintPhase = await readonlyContract.mintPhase();
//   if (props.activeAddress) {
//     const getTokensByAddress = {
//       0: readonlyContract.freeWalletMints,
//       1: readonlyContract.preWalletMints,
//       2: readonlyContract.publicWalletMints,
//     }[contractMintPhase];

//     const addressTokens = await getTokensByAddress(props.activeAddress);
//     props.setAddressAmountMinted(addressTokens.toString());
//   }
// };

window.isMobile = (() => {
  let check = false;
  ((a) => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);

  return check;
})();

const getContractInfo = async (props) => {
  if (!readonlyContract) return;
  if (DO_MANUAL_TESTING) return;

  const isContractPaused = await readonlyContract.isPaused();
  props.setIsContractPaused(isContractPaused);

  const isContractWhitelistEnabled = await readonlyContract.isAllowListEnabled();
  props.setIsWhitelistEnabled(isContractWhitelistEnabled);

  const contractMintPhase = await readonlyContract.mintPhase();
  const mintPhase = {
    0: 'free',
    1: 'pre',
    2: 'public',
  }[contractMintPhase];
  props.setMintPhase(mintPhase);

  const contractSoldAmount = {
    0: 0,
    1: 500,
    2: 2500,
  }[contractMintPhase];
  const totalSupply = await readonlyContract.totalSupply(); // amount sold from contract.
  const contractAmountMinted = totalSupply - contractSoldAmount;

  props.setIsPhaseSoldOut(contractAmountMinted === props.phaseMaxAvailable);
  props.setPhaseAmountRemaining(props.phaseMaxAvailable - contractAmountMinted);

  if (props.activeAddress) {
    const getTokensByAddress = {
      0: readonlyContract.freeWalletMints,
      1: readonlyContract.preWalletMints,
      2: readonlyContract.publicWalletMints,
    }[contractMintPhase];

    const addressTokens = await getTokensByAddress(props.activeAddress);
    props.setAddressAmountMinted(addressTokens.toString());
  }
};

const connectWallet = async (props) => {
  // web3Modal.clearCachedProvider();

  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum); // MetaMask Provider
    const accounts = await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();

    const address = await signer.getAddress();
    props.setActiveAddress(accounts[0]);

    const merkleProof = Whitelist.getProofForAddress(address);
    const isAllowListed = await readonlyContract.isAllowListed(address, merkleProof);
    props.setIsWhitelisted(isAllowListed);

    readwriteContract = new ethers.Contract(CONTRACT_ADDRESS, gneeksGouAbi, signer);

    // const balance = await provider.getBalance(address);
    // const formattedBalance = ethers.utils.formatEther(balance);
    // props.setAddressEthBalance(formattedBalance);
  } catch (error) {
    // console.log('error', error);
  }
};

const CountDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) return <></>;

  return (
    <span className="mint-text countdown">
      {formatDuration({ days, hours, minutes, seconds }, { delimiter: ', ', zero: true })} left
    </span>
  );
};

const Dapp = (props) => {
  const [numberToMint, setNumberToMint] = useState(props.addressUnmintedCount);
  const maxMintable = Math.min(numberToMint, props.addressUnmintedCount);
  const isOpenMint = !props.isContractWhitelistEnabled || props.mintPhase === 'public';

  const mintNft = async () => {
    if (props.isContractPaused) return;

    try {
      let merkleProof = [];
      if (props.isContractWhitelistEnabled) {
        merkleProof = Whitelist.getProofForAddress(props.activeAddress);
      }

      const price = {
        free: '0',
        pre: '0.1',
        public: '0.2',
      }[props.mintPhase];

      const mint = await readwriteContract.mint(numberToMint, merkleProof, {
        value: ethers.utils.parseEther(price),
      });
      await mint.wait();

      const contractMintPhase = await readonlyContract.mintPhase();
      if (props.activeAddress) {
        const getTokensByAddress = {
          0: readonlyContract.freeWalletMints,
          1: readonlyContract.preWalletMints,
          2: readonlyContract.publicWalletMints,
        }[contractMintPhase];

        const addressTokens = await getTokensByAddress(props.activeAddress);
        props.setAddressAmountMinted(addressTokens.toString());
      }
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    const doStuff = async () => {
      const contractMintPhase = await readonlyContract.mintPhase();
      if (props.activeAddress) {
        const getTokensByAddress = {
          0: readonlyContract.freeWalletMints,
          1: readonlyContract.preWalletMints,
          2: readonlyContract.publicWalletMints,
        }[contractMintPhase];
        const addressTokens = await getTokensByAddress(props.activeAddress);
        props.setAddressAmountMinted(addressTokens.toString());
      }
    };

    doStuff();
  }, [props.activeAddress]); //eslint-disable-line

  // Update contract state every 10 seconds.
  useInterval(() => {
    if (!DO_MANUAL_TESTING) {
      getContractInfo(props);
    }
  }, 10000);

  // Update wallet connection and contract state on mount.
  useEffect(() => {
    if (!DO_MANUAL_TESTING) {
      // connectWallet(props);
      getContractInfo(props);
    }
  }, []); // eslint-disable-line

  // Add listener for wallet changes on mount.
  useEffect(() => {
    if (!window.ethereum) return;

    const onAccountsChange = async (connectedAddresses) => {
      const address = connectedAddresses[0];
      props.setActiveAddress(address);
      connectWallet(props);

      const contractMintPhase = await readonlyContract.mintPhase();
      if (props.activeAddress) {
        const getTokensByAddress = {
          0: readonlyContract.freeWalletMints,
          1: readonlyContract.preWalletMints,
          2: readonlyContract.publicWalletMints,
        }[contractMintPhase];

        const addressTokens = await getTokensByAddress(props.activeAddress);
        props.setAddressAmountMinted(addressTokens.toString());
      }
    };

    window.ethereum.on('accountsChanged', onAccountsChange);

    return () => {
      window.ethereum.off('accountsChanged', onAccountsChange);
    };
  }, []); // eslint-disable-line

  return (
    <div className="mint-dapp-wrapper">
      <Container>
        {/* <Row>
          <span className="mint-text phase-label">Current Minting Phase</span>
        </Row> */}

        <Row>
          <span className="mint-text burbank phase ">
            {props.isContractPaused ? 'Paused' : `${props.mintPhase} Mint`}
          </span>
        </Row>
        <Row>
          <span className="mint-text burbank phase phase-message">
            {props.isContractPaused && `${props.mintPhase} Mint Coming Soon!`}
            {props.isPhaseSoldOut && !props.isContractPaused && `Sold Out!`}
          </span>
        </Row>

        {props.isPhaseSoldOut && (
          <>
            <Row>
              <span className="mint-text sold-out-message buy-now">
                <a
                  href="https://opensea.io/collection/gneeks-gods-and-geeks"
                  target="_blank"
                  className="text-yellow"
                  rel="noreferrer"
                >
                  Buy now on OpenSea.
                </a>{' '}
              </span>
            </Row>
            <Row>
              <span className="mint-text sold-out-message">
                Don&apos;t miss the Pre-mint phase! <br />
                <a
                  href="https://discord.gg/XXdEwbJ82z"
                  className="text-yellow"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join Discord
                </a>{' '}
                <strong>to get whitelisted and lock in a {0.1} mint price.</strong>
              </span>
            </Row>
          </>
        )}

        {!props.isContractPaused && !props.isPhaseSoldOut && props.phaseMaxAvailable && (
          <Row>
            <span className="mint-text available">
              <strong>{props.phaseAmountRemaining}</strong> of {props.phaseMaxAvailable} available
            </span>
            <Countdown date={MINT_END_DATETIME} renderer={CountDownRenderer} />
          </Row>
        )}

        {props.isWalletConnected && (
          <>
            <Row>
              <span className="mint-text connected-label">✅ Connected to MetaMask</span>
            </Row>
            <Row>
              <span className="mint-text active-address-label">Active address is...</span>
            </Row>
            <Row>
              <span className="mint-text address-text">{props.activeAddress}</span>
            </Row>
            <Row>
              <span className="mint-text address-text">{props.addressEthBalance}</span>
            </Row>
          </>
        )}

        {!props.isContractPaused && !props.isPhaseSoldOut && props.isWalletConnected && (
          <>
            <Row>
              <span className="mint-text connected-label">Eligibililty</span>
            </Row>

            <Row>
              <span className="mint-text address-text">
                {props.addressMintableCount} mint(s) allowed per address.
              </span>
            </Row>

            {!isOpenMint && !props.isAddressWhitelisted ? (
              <Row>
                <span className="mint-text">Sorry, the active address is not whitelisted. 😢</span>
              </Row>
            ) : (
              <>
                <Row>
                  <span className="mint-text already-minted">
                    Already minted <strong>{props.addressAmountMinted}</strong>.
                  </span>
                </Row>

                {props.addressUnmintedCount === 0 ? null : (
                  <Row xs="auto" className="mint-picker-row box-border">
                    <Col>
                      <span className="mint-text already-minted box-border">Mint </span>
                    </Col>

                    <Col className="box-border mint-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle
                          className="dropdown-toggle dropdown-font"
                          id="numberToMint-dropdown"
                        >
                          {maxMintable}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Array.from({ length: props.addressUnmintedCount }, (_, i) => (
                            <Dropdown.Item onClick={() => setNumberToMint(i + 1)}>
                              {i + 1}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>

                    <Col className="box-border">
                      <span className="mint-text already-minted box-border">
                        of {props.addressUnmintedCount} remaining
                      </span>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        )}

        <>
          {props.isWalletConnected ? (
            <>
              {!props.isContractPaused &&
                !props.isPhaseSoldOut &&
                props.addressUnmintedCount > 0 &&
                ((props.mintPhase !== 'public' && props.isAddressWhitelisted) || isOpenMint) && (
                  <button className="button-back mint-button-back" onClick={mintNft}>
                    <span className="button-front mint-button-front lead _burbank">Mint!</span>
                  </button>
                )}
            </>
          ) : (
            <button
              className={`button-back connect-button-back ${
                props.isPhaseSoldOut && 'button-sold-out'
              }`}
              onClick={() => connectWallet(props)}
            >
              <span
                className={`button-front connect-button-front _burbank ${
                  props.isPhaseSoldOut && 'button-sold-out'
                }`}
              >
                Connect MetaMask
              </span>
            </button>
          )}

          {window.isMobile && !props.isPhaseSoldOut && (
            <div className="mobile-detected">
              <Row>
                <span>👋 Hi!</span>
                <span>
                  On mobile devices the <b>Connect</b> button only works in the MetaMask app.
                </span>
                <br />
                <br />
                <a href="https://metamask.app.link/" className="metamask-link">
                  Open MetaMask App
                </a>
                <span>📱 Go to Menu {'>'} Browser</span>
                <span>
                  🔍 Go to <b>gneeks.org</b>.
                </span>
                <span>✅ Connect & Mint!</span>
              </Row>
            </div>
          )}
        </>
      </Container>
    </div>
  );
};

const MintingDapp = () => {
  const [activeAddress, setActiveAddress] = useState();
  const [addressAmountMinted, setAddressAmountMinted] = useState(0);
  const [addressEthBalance, setAddressEthBalance] = useState();
  const [isAddressWhitelisted, setIsWhitelisted] = useState(false);
  const [isContractPaused, setIsContractPaused] = useState(false);
  const [isContractWhitelistEnabled, setIsWhitelistEnabled] = useState(true);
  const [isPhaseSoldOut, setIsPhaseSoldOut] = useState(false);
  const [mintPhase, setMintPhase] = useState('free');
  const [phaseAmountRemaining, setPhaseAmountRemaining] = useState(0);

  const addressMintableCount = {
    free: 1,
    pre: 5,
    public: 25,
  }[mintPhase];

  const addressUnmintedCount = {
    free: 1 - addressAmountMinted,
    pre: 5 - addressAmountMinted,
    public: 25 - addressAmountMinted,
  }[mintPhase];

  const phaseMaxAvailable = {
    free: 500,
    pre: 2000,
    public: 7500,
  }[mintPhase];

  const sharedProps = {
    // actions
    setActiveAddress,
    setAddressAmountMinted,
    setAddressEthBalance,
    setIsContractPaused,
    setIsPhaseSoldOut,
    setIsWhitelisted,
    setIsWhitelistEnabled,
    setMintPhase,
    setPhaseAmountRemaining,

    // state
    activeAddress,
    addressAmountMinted,
    addressEthBalance,
    addressMintableCount,
    addressUnmintedCount,
    isAddressWhitelisted,
    isContractPaused,
    isContractWhitelistEnabled,
    isPhaseSoldOut,
    isUserOnMobile: !!localStorage.mobile || window.navigator?.maxTouchPoints > 1, // https://stackoverflow.com/a/71030087/25197
    isWalletConnected: !!activeAddress,
    mintPhase,
    phaseAmountRemaining,
    phaseMaxAvailable,
  };

  // console.log({ ...sharedProps });

  return (
    <>
      {DO_MANUAL_TESTING && <MintingDappStateTesting {...sharedProps} />}
      <Dapp {...sharedProps} />
    </>
  );
};

export { MintingDapp };
