import React from 'react';

import { Footer, Nav } from '../../components';
import { AppFeatures, Hero, OnPhone, Video } from '../../containers/App';

const AppScreen = () => {
  return (
    <div>
      <Nav />
      <Hero />
      <OnPhone />
      <AppFeatures />
      <Video />
      <Footer />
    </div>
  );
};

export default AppScreen;
