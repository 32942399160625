import './preview.css';

import React from 'react';
import { Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';

const Preview = () => {
  const undergrounds = [
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/comic/comic-preview-1.png',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/comic/comic-preview-2.png',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/class-x-appstore-app-iphone.png',
    },
    {
      image:
        'https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/class-x-node-chronicles-appstore-comic-preview.png',
    },
  ];

  const options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <div className="preview_section">
      <Container>
        <div className="col-md-8 ui-layout-column-center">
          {undergrounds.length && (
            <OwlCarousel className="owl-theme" {...options}>
              {undergrounds.map((v) => {
                return (
                  <div className="" key={v.image}>
                    <img src={v.image} className="" alt="" style={{ width: '100%' }} />
                  </div>
                );
              })}
            </OwlCarousel>
          )}
          <br />
          <p className="text-white text-center courier">
            <b>Flashback:</b> Chino enlists Vero&apos;s help to create the first natural discrete
            particle encoder. After realizing they&apos;ve discoverd a dangerous cellular automation
            equation, Chino decides to shut the project down and hide the work underground. Years
            later, Gou finds the papers and all heck breaks loose.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Preview;
